import { Button, Form, Modal, Col, Row, Input, Select, message } from 'antd';

import { usePostAs, useGetUserEquipments, useGetEquipments } from '../../../../apis';
import { useUserInfo } from '../../../../hooks';

import { type } from './constants';

const AddNewAsModal = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm();

  const { data: userEquipments } = useGetUserEquipments();
  const { data: equipments } = useGetEquipments();
  const { mutate: postAs, isLoading } = usePostAs();
  const { userInfo, isCustomer } = useUserInfo();

  const closeModal = () => {
    form.resetFields();
    setIsOpen((isPrev) => !isPrev);
  };

  const onPostAs = (values) => {
    postAs(
      {
        serialNo: values.serialNo,
        type: values.type,
        memo: values.memo,
      },
      {
        onSuccess: (res) => {
          if (!res.success) return message.error('A/S접수에 실패했습니다.');
          closeModal();
          return message.success('A/S등록이 완료되었습니다.');
        },
      },
    );
  };

  const asItems = isCustomer
    ? userEquipments?.serialNos?.map((data) => ({ label: data, value: data }))
    : equipments?.map((data) => ({ label: data.serialNo, value: data.serialNo }));

  return (
    <Modal width={640} maskClosable={false} title="A/S 신청" onCancel={closeModal} open={isOpen} footer={null}>
      <Form form={form} layout="vertical" onFinish={onPostAs}>
        <Row align={'top'} justify={'center'} wrap={false} gutter={[24, 24]}>
          <Col flex={'33.333334%'}>
            <Form.Item label="작성자">
              <Input readOnly value={userInfo?.name} />
            </Form.Item>
          </Col>
          <Col flex={'33.333334%'}>
            <Form.Item label="장비일련번호" name="serialNo">
              <Select options={asItems} />
            </Form.Item>
          </Col>
          <Col flex={'33.333334%'}>
            <Form.Item label="접수구분" name="type">
              <Select options={type} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="증상 설명" name="memo">
          <Input.TextArea style={{ minHeight: 150 }} />
        </Form.Item>
        <Row align={'middle'} justify={'space-between'}>
          <Button onClick={closeModal}>취소</Button>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            신청하기
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddNewAsModal;
