import dayjs from 'dayjs';
import { Tag } from 'antd';

export const columns = [
  {
    title: '분류',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    width: 80,
    sorter: (a, b) => a.type.localeCompare(b.type),
    render: (_, record) => {
      if (record.type === 'INFO') return <Tag color="#87d068">정보</Tag>;
      if (record.type === 'WARN') return <Tag color="#f50">경고</Tag>;
      if (record.type === 'ALARM') return <Tag color="#108ee9">알람</Tag>;
    },
  },
  {
    title: '업체명',
    dataIndex: 'customerName',
    key: 'customerName',
    width: 100,
    render: () => 'Ad',
    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
  },
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
    width: 180,
    sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
  },
  { title: '내용', dataIndex: 'message', key: 'message', width: 600, ellipsis: true },
  {
    title: '발생일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 160,
    sorter: (a, b) => dayjs(a.createdAt) - dayjs(b.createdAt),
    render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
  },
];
