import { Row, Col, Modal, Form, Input, InputNumber, DatePicker, Select, Space, Button, Divider, message } from 'antd';
import classNames from 'classnames/bind';
import { useDaumPostcodePopup } from 'react-daum-postcode';

import { useGetCustomers, usePostEquipments, useGetComponents, useGetUsers } from '../../../../../apis';
import { integerFormatter } from '../../../../../utils';

import styles from './index.module.scss';
import { useEffect } from 'react';
import dayjs from 'dayjs';

const cx = classNames.bind(styles);

const AddEquipmentModal = ({ isOpen, setIsOpen }) => {
  const [addEquipmentForm] = Form.useForm();
  const watch = Form.useWatch([], addEquipmentForm);

  const { data: customerData } = useGetCustomers();
  const { data: componentsData } = useGetComponents();
  const { data: usersData } = useGetUsers();
  const { mutate: postEquipment, isLoading: isPostLoading } = usePostEquipments();

  const openPostCodePopup = useDaumPostcodePopup();

  const closeModal = () => {
    addEquipmentForm.resetFields();
    setIsOpen((isPrev) => !isPrev);
  };

  const handleGetAddress = () => {
    openPostCodePopup({
      onComplete: (addressObj) => {
        addEquipmentForm.setFieldValue(`installAddress`, addressObj.address);
      },
    });
  };

  const onPostEquipment = (values) => {
    if (componentsData)
      postEquipment(
        {
          customerRid: values.customerRid,
          serialNo: values.serialNo,
          model: values.model,
          softwareVersion: `${values.majorSoftwareVersion}.${values.minorSoftwareVersion}.${values.patchSoftwareVersion}`,
          boosterType: values.boosterType,
          boosterMethod: values.boosterMethod,
          boosterTime: values.boosterTime,
          installedDate: dayjs(values.installedDate).format('YYYYMMDD'),
          lastCheckDate: dayjs(values.installedDate).format('YYYY-MM-DD'),
          latitude: values.latitude,
          longitude: values.longitude,
          manufacturedDate: dayjs(values.manufacturedDate).format('YYYYMMDD'),
          installAddress: values.installAddress,
          manager: values.manager,
          no: values.no,
          successChargeCount: values.successChargeCount,
          checkDday: values.checkDday,
          installAddressDetail: values.installAddressDetail,
          memo: values.memo,
          componentInspections: componentsData.map((data) => {
            const period = values[data.rid];
            const periodType = values[`${data.rid}_date`];
            return {
              componentRid: data.rid,
              period: period,
              periodType: periodType,
              inspectionDate: dayjs().format('YYYYMMDD'),
            };
          }),
        },
        {
          onSuccess: (res) => {
            if (!res.success) return message.error('장비 등록에 실패했습니다.');
            closeModal();
            return message.success('등록이 완료되었습니다.');
          },
        },
      );
  };

  useEffect(() => {
    if (componentsData) {
      let initComponentsDate = {};
      for (let i = 0; i < componentsData.length; i++) {
        initComponentsDate[componentsData[i].rid + '_date'] = 'YEARS';
      }
      addEquipmentForm.setFieldsValue({ ...initComponentsDate });
    }
  }, [componentsData, isOpen]);

  return (
    <Modal
      onCancel={closeModal}
      title="장비등록"
      maskClosable={false}
      footer={null}
      className={cx('wrapper')}
      width={1080}
      centered
      open={isOpen}>
      <Form layout="vertical" form={addEquipmentForm} onFinish={onPostEquipment}>
        <div>
          <strong className={cx('subTitle')}>기본정보</strong>
          <Row align={'top'} justify={'space-between'} gutter={[24, 24]}>
            <Col flex={'33.333334%'}>
              <Space>
                <Form.Item
                  name="customerRid"
                  label="업체명"
                  rules={[{ required: true, message: '업체명을 선택해주세요' }]}>
                  <Select
                    style={{ width: 120 }}
                    options={customerData?.map((data) => ({ label: data.name, value: data.rid }))}
                  />
                </Form.Item>

                <Form.Item name="manager" label="담당자" rules={[{ required: true, message: '담당자를 작성해주세요' }]}>
                  <Select
                    disabled={!watch?.customerRid}
                    style={{ width: 160 }}
                    options={usersData
                      ?.filter((user) => user.customer?.rid === watch?.customerRid && user.role === 'CUSTOMER')
                      .map((data, index) => ({ value: data.name, label: data.name, key: index }))}
                  />
                </Form.Item>
              </Space>

              <Form.Item
                name="serialNo"
                label="일련번호"
                rules={[{ required: true, message: '일련번호를 작성해주세요' }]}>
                <Input />
              </Form.Item>

              <Form.Item name="model" label="모델명" rules={[{ required: true, message: '모델명을 작성해주세요' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="manufacturedDate"
                label="제조일자"
                rules={[{ required: true, message: '제조일자를 선택해주세요' }]}>
                <DatePicker />
              </Form.Item>

              <Space.Compact>
                <Form.Item name="majorSoftwareVersion" label="Major" rules={[{ required: true, message: '' }]}>
                  <InputNumber min={0} max={99} />
                </Form.Item>
                <Form.Item name="minorSoftwareVersion" label="Minor" rules={[{ required: true, message: '' }]}>
                  <InputNumber min={0} max={99} />
                </Form.Item>
                <Form.Item name="patchSoftwareVersion" label="Patch" rules={[{ required: true, message: '' }]}>
                  <InputNumber min={0} max={99} />
                </Form.Item>
              </Space.Compact>
            </Col>

            <Col flex={'33.333334%'}>
              <Form.Item label="호기" name="no" rules={[{ required: true, message: '호기를 작성해주세요' }]}>
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item
                name="installAddress"
                label="설치 주소"
                rules={[{ required: true, message: '주소를 작성해주세요' }]}>
                <Input.Search onClick={handleGetAddress} onSearch={handleGetAddress} />
              </Form.Item>
              <Form.Item
                name="installAddressDetail"
                label="상세주소"
                rules={[{ required: true, message: '상세주소를 작성해주세요' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                label="점검카운트(day)"
                name="checkDday"
                rules={[{ required: true, message: '점검카운트를 작성해주세요' }]}>
                <InputNumber step={1} min={1} formatter={integerFormatter} />
              </Form.Item>

              <Form.Item
                label="충전완료횟수"
                name="successChargeCount"
                rules={[{ required: true, message: '충전완료 횟수를 작성해주세요' }]}>
                <InputNumber step={1} min={1} formatter={integerFormatter} />
              </Form.Item>
            </Col>

            <Col flex={'33.333334%'}>
              <Form.Item
                name="boosterMethod"
                label="부스터방식"
                rules={[{ required: true, message: '부스터방식을 선택해주세요' }]}>
                <Select
                  options={[
                    { label: '에어', value: 'AIR' },
                    { label: '전기/단상 110V', value: 'EV110' },
                    { label: '전기/단상 220V', value: 'EV220' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="boosterTime"
                label="부스터 최대 사용시간"
                rules={[{ required: true, message: '부스터 최대 사용시간을 작성해주세요' }]}>
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="boosterType"
                label="부스터 타입"
                rules={[{ required: true, message: '부스터 타입을 선택해주세요' }]}>
                <Select
                  options={[
                    { value: 'Maximator', label: 'Maximator(DLE-30-75)' },
                    { value: 'HII', label: 'HII(HIHPG4-21397)' },
                    { value: 'Haskel', label: 'Haskel(AGT-32/62)' },
                  ]}
                />
              </Form.Item>

              <Form.Item
                name="installedDate"
                label="설치일자"
                rules={[{ required: true, message: '설치일자를 선택해주세요' }]}>
                <DatePicker />
              </Form.Item>

              <Space.Compact>
                <Form.Item name="latitude" label="위도" rules={[{ required: true, message: '' }]}>
                  <InputNumber />
                </Form.Item>

                <Form.Item name="longitude" label="경도" rules={[{ required: true, message: '' }]}>
                  <InputNumber />
                </Form.Item>
              </Space.Compact>
            </Col>
          </Row>
          <Divider />
          <strong className={cx('subTitle')}>소모품 정보(점검주기)</strong>
          <div className={cx('flexBox')}>
            {componentsData &&
              componentsData.map((data, index) => {
                const key = `component_type_${index}`;

                return (
                  <div key={key} className={cx('box')}>
                    <Form.Item name={data.rid} label={data.name} rules={[{ required: true, message: '' }]}>
                      <InputNumber min={0} style={{ width: 100 }} />
                    </Form.Item>
                    <Form.Item name={`${data.rid}_date`}>
                      <Select
                        style={{ minWidth: 60 }}
                        options={[
                          { label: '년', value: 'YEARS' },
                          { label: '개월', value: 'MONTH' },
                          { label: '일', value: 'DAY' },
                        ]}
                      />
                    </Form.Item>
                  </div>
                );
              })}
          </div>
          <Divider />
          <strong className={cx('subTitle')}>부가정보</strong>
          <Form.Item label="메모" name="memo">
            <Input.TextArea className={cx('textareaBox')} />
          </Form.Item>
        </div>
        <Row align={'middle'} justify={'space-between'}>
          <Button type="primary" ghost onClick={closeModal}>
            취소
          </Button>
          <Button type="primary" htmlType="submit" loading={isPostLoading} disabled={isPostLoading}>
            저장
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEquipmentModal;
