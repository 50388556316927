export const alarmColumns = [
  {
    title: '업체명',
    dataIndex: 'customer',
    key: 'customer',
    sorter: (a, b) => a.customer.localeCompare(b.customer),
  },
  {
    title: '장비명',
    dataIndex: 'serialNo',
    key: 'serialNo',
  },
  {
    title: '정보',
    dataIndex: 'info',
    key: 'info',
    align: 'center',
    sorter: (a, b) => a.info - b.info,
  },
  {
    title: '경고',
    dataIndex: 'warning',
    key: 'warning',
    align: 'center',
    sorter: (a, b) => a.info - b.info,
  },
  {
    title: '알림',
    dataIndex: 'alarm',
    key: 'alarm',
    align: 'center',
    sorter: (a, b) => a.info - b.info,
  },
];

export const ALARM_DUMMY = [
  {
    key: '1',
    customer: 'DMI',
    serialNo: 'lastFor',
    info: 20,
    warning: 12,
    alarm: 5,
  },
  {
    key: '2',
    customer: 'DMI',
    serialNo: '냠냠일련',
    info: 19,
    warning: 52,
    alarm: 0,
  },
  {
    key: '3',
    customer: 'TMI',
    serialNo: 'sakura-369',
    info: 12,
    warning: 32,
    alarm: 85,
  },
];

export const columns = [
  {
    title: '업체명',
    dataIndex: 'customer',
    key: 'customer',
    render: (_, record) => {
      if (!record.customer) return '전체';
      return record.customer.name;
    },
  },
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
    width: 150,
    render: (_, record) => {
      if (!record.serialNo) return '전체';
      return record.serialNo;
    },
  },
  {
    title: 'A/S접수구분',
    children: [
      {
        title: '에러발생',
        dataIndex: 'error',
        key: 'error',
        align: 'center',
        render: (_, record) => record.as.ERROR,
      },
      {
        title: '동작이상',
        dataIndex: 'warning',
        key: 'warning',
        align: 'center',
        render: (_, record) => record.as.WARNING,
      },
      {
        title: '점검요청',
        dataIndex: 'request',
        key: 'request',
        align: 'center',
        render: (_, record) => record.as.REQUEST,
      },
      {
        title: '개선요청',
        dataIndex: 'update',
        key: 'update',
        align: 'center',
        render: (_, record) => record.as.UPDATE,
      },
      {
        title: '기타',
        dataIndex: 'asEtc',
        key: 'asEtc',
        align: 'center',
        render: (_, record) => record.as.OTHER,
      },
    ],
  },
  {
    title: 'AS접수구분합계',
    dataIndex: 'asSum',
    key: 'asSum',
    align: 'center',
    width: 120,
  },
  {
    title: '방문횟수',
    dataIndex: 'totalVisitCount',
    key: 'totalVisitCount',
    align: 'center',
  },
  {
    title: '점검구분',
    children: [
      {
        title: '하드웨어',
        dataIndex: 'hardware',
        key: 'hardware',
        align: 'center',
        render: (_, record) => record.meet.HARDWARE,
      },
      {
        title: '소프트웨어',
        dataIndex: 'software',
        key: 'software',
        align: 'center',
        render: (_, record) => record.meet.SOFTWARE,
      },
      {
        title: '소프트/하드',
        dataIndex: 'soft/hard',
        key: 'soft/hard',
        align: 'center',
        render: (_, record) => record.meet['HARD/SOFT'],
      },
      {
        title: '정기점검',
        dataIndex: 'always',
        key: 'always',
        align: 'center',
        render: (_, record) => record.meet.ALWAYS,
      },
      {
        title: '기타',
        dataIndex: 'meetEtc',
        key: 'meetEtc',
        align: 'center',
        render: (_, record) => record.meet.OTHER,
      },
    ],
  },
  {
    title: '점검구분합계',
    dataIndex: 'meetSum',
    key: 'meetSum',
    align: 'center',
    width: 100,
  },
];
