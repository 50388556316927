import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { equipmentsKey } from '../../key';
import { axiosInstance } from '../../base';
import { useUserInfo } from '../../../hooks';

const apiUrl = '/equipments';

const getEquipments = async () => {
  const response = await axiosInstance.get(apiUrl, {});
  return response;
};

const boosterTypeCreator = (boosterType) => {
  if (boosterType === 'Maximator') return 'Maximator(DLE-30-75)';
  if (boosterType === 'HII') return 'HII(HIHPG4-21397)';
  if (boosterType === 'Haskel') return 'Haskel(AGT-32/62)';
  return boosterType;
};

export const useGetEquipments = () => {
  const { isCustomer, userInfo } = useUserInfo();

  return useQuery({
    queryFn: getEquipments,
    queryKey: equipmentsKey.all,
    select: (response) => {
      const resultData = response.data.map((data) => ({
        ...data,
        // TODO : DUMMY DATA
        useTime: 2000,
        boosterType: boosterTypeCreator(data.boosterType),
        updatedAt: dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
        createdAt: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      }));

      if (isCustomer) {
        return resultData.filter((data) => data.customer?.name === userInfo?.customer?.name);
      }

      return resultData;
    },
  });
};
