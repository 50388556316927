import { useMutation, useQueryClient } from 'react-query';

import { equipmentsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments';

const postEquipments = async (requestData) => {
  const response = await axiosInstance.post(apiUrl, requestData);
  return response;
};

export const usePostEquipments = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postEquipments,
    onSuccess: () => {
      queryClient.invalidateQueries(equipmentsKey.all);
    },
  });
};
