import { useMutation, useQueryClient } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as';

const deleteAs = async (requestData) => {
  const response = await axiosInstance.delete(`${apiUrl}/${requestData.rid}`);
  return response;
};

export const useDeleteAs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAs,
    onSuccess: () => {
      queryClient.invalidateQueries(asKey.all);
    },
  });
};
