import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Form, Input, Space, message } from 'antd';
import classNames from 'classnames/bind';

import LOGO_IMAGE from '../../assets/common/logo.png';
import { useLogin } from '../../apis';
import { baseURL } from '../../apis/base';
import { saveAccessToken } from '../../utils';
import { useUserInfo } from '../../hooks/userInfo';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const LoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: postLogin } = useLogin();

  const { updateUserInfo, updateUserRole } = useUserInfo();

  const onLogin = (values) => {
    const { id, password } = values;

    postLogin(
      {
        id,
        password,
      },
      {
        onSuccess: async ({ success, data }) => {
          if (!success)
            return message.error({
              key: 'loginError',
              content: '로그인에 실패했습니다.',
            });

          saveAccessToken(data.accessToken);
          axios
            .get(`${baseURL}/users/info`, {
              headers: {
                Authorization: `Bearer ${data.accessToken}`,
              },
            })
            .then(({ data }) => {
              updateUserInfo(data.data);
              const isAdmin = data.data.role === 'ADMIN' || data.data.role === 'SUB-ADMIN';
              updateUserRole(isAdmin);
              navigate('/');
            });
        },
      },
    );
  };

  return (
    <div className={cx('wrapper')}>
      <Form
        onFinish={onLogin}
        form={form}
        layout="vertical"
        initialValues={{
          id: 'admin',
          password: '1234',
        }}>
        <div className={cx('loginBox')}>
          <img className={cx('logo')} src={LOGO_IMAGE} alt="BTE 로그인" />

          <Space direction="vertical" className={cx('formInputBox')}>
            <Form.Item name="id" label="아이디" rules={[{ required: true, message: '아이디를 입력해주세요' }]}>
              <Input size="large" />
            </Form.Item>

            <Form.Item
              name="password"
              label="비밀번호"
              rules={[{ required: true, message: '비밀번호를 입력해주세요' }]}>
              <Input type="password" size="large" />
            </Form.Item>

            <Button htmlType="submit" type="primary" block size="large">
              Login
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
