export const chartAsNameCreator = (type) => {
  if (type === 'ERROR') return '에러발생';
  if (type === 'WARNING') return '동작이상';
  if (type === 'REQUEST') return '점검요청';
  if (type === 'UPDATE') return '개선요청';
  return '기타';
};

export const chartMeetNameCreator = (type) => {
  if (type === 'HARDWARE') return '하드웨어';
  if (type === 'SOFTWARE') return '소프트웨어';
  if (type === 'HARD/SOFT') return '하드/소프트';
  if (type === 'ALWAYS') return '정기점검';
  return '기타';
};
