import { Button, DatePicker, Modal, Table, InputNumber, Space, Select, message } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import { usePatchEquipmentComponent, useGetComponents } from '../../../../../apis';

import { columns } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const ChangeComponents = ({ isOpen, setIsOpen, rid, components }) => {
  const [tableData, setTableData] = useState([]);

  const { mutate: patchEquipmentComponent } = usePatchEquipmentComponent();
  const { data: componentsData } = useGetComponents();

  const onPatchEquipmentComponent = (values) => {
    patchEquipmentComponent(
      {
        rid: rid,
        componentRid: values.componentRid,
        period: values.period,
        periodType: values.periodType,
        inspectionDate: values.inspectionDate,
      },
      {
        onSuccess: (res) => {
          if (!res.success) return message.error('변경에 실패했습니다.');
          return message.success('변경 완료되었습니다.');
        },
      },
    );
  };

  useEffect(() => {
    if (componentsData && components) {
      const mixTwoComponents = components
        .map((data) => {
          const matchingComponent = componentsData.find((data2) => data2.rid === data.componentRid);
          if (matchingComponent) {
            return { ...data, name: matchingComponent.name };
          }
          return null;
        })
        .filter((data3) => data3 !== null);

      setTableData(mixTwoComponents);
    }
  }, [componentsData, components]);

  return (
    <Modal
      width={640}
      centered
      maskClosable={false}
      open={isOpen}
      title="소모품 점검 관리"
      onCancel={() => setIsOpen((isPrev) => !isPrev)}
      footer={null}>
      <Table
        size="small"
        pagination={false}
        key={'componentRid'}
        rowKey="componentRid"
        columns={[
          ...columns,
          {
            title: '점검주기',
            width: 200,
            dataIndex: 'period',
            key: 'period',
            render: (_, record) => {
              return (
                <Space.Compact>
                  <InputNumber
                    min={0}
                    defaultValue={record.period}
                    style={{ width: 80 }}
                    onChange={(value) =>
                      setTableData((prevData) =>
                        prevData.map((data) => {
                          if (data.componentRid === record.componentRid) return { ...data, period: value };

                          return data;
                        }),
                      )
                    }
                  />
                  <Select
                    style={{ width: 80 }}
                    defaultValue={record.periodType}
                    onChange={(value) =>
                      setTableData((prevData) =>
                        prevData.map((data) => {
                          if (data.componentRid === record.componentRid) return { ...data, periodType: value };
                          return data;
                        }),
                      )
                    }
                    options={[
                      { label: '년', value: 'YEARS' },
                      { label: '개월', value: 'MONTH' },
                      { label: '일', value: 'DAY' },
                    ]}
                  />
                </Space.Compact>
              );
            },
          },
          {
            title: '점검일자',
            dataIndex: 'inspectionDate',
            key: 'inspectionDate',
            render: (_, record) => (
              <DatePicker
                onChange={(value) =>
                  setTableData((prevData) =>
                    prevData.map((data) => {
                      if (data.componentRid === record.componentRid) return { ...data, inspectionDate: value };
                      return data;
                    }),
                  )
                }
                defaultValue={dayjs(record.inspectionDate)}
              />
            ),
          },
          {
            title: '변경',
            dataIndex: 'componentRid',
            key: 'componentRid',
            render: (_, record) => (
              <Button
                onClick={() =>
                  onPatchEquipmentComponent({
                    componentRid: record.componentRid,
                    period: record.period,
                    periodType: record.periodType,
                    inspectionDate: record.inspectionDate,
                  })
                }
                size="small">
                변경
              </Button>
            ),
          },
        ]}
        dataSource={tableData || []}
      />
    </Modal>
  );
};

export default ChangeComponents;
