export const EVENTS = [
  {
    type: 'WARN',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350-23-001',
    message: 'Power on',
    createdAt: '2013-06-20 10:13:31',
    isRead: true,
  },
  {
    type: 'WARN',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350-23-001',
    message: 'Charge start(S1: 50, S2: N)',
    createdAt: '2013-06-20 10:20:17',
    isRead: true,
  },
  {
    type: 'ALARM',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350-23-001',
    message: 'TT102 High(43.5)',
    createdAt: '2013-06-20 11:50:45',
    isRead: true,
  },
  {
    type: 'INFO',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350-23-001',
    message: 'TT102 High(45.3)',
    createdAt: '2013-06-20 12:03:24',
    isRead: true,
  },
  {
    type: 'WARN',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350-23-001',
    message: 'Charge finish(S1: 313, S2: N)',
    createdAt: '2013-06-20 12:08:15',
    isRead: true,
  },
  {
    type: 'WARN',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350_D_2',
    message: '이벤트 메시지 내용입니다.',
    createdAt: '2023-10-23 12:00:00',
    isRead: true,
  },
  {
    type: 'ALARM',
    customerId: '9f642323-538e-42b3-a890-71c9a8da08a7',
    serialNo: 'BHR-ST-350_D_1',
    message:
      '이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트',
    createdAt: '2023-06-23 12:00:00',
    isRead: true,
  },
  {
    type: 'ALARM',
    customerId: '9f642323-538e-42b3-a890-71c9a8da08a7',
    serialNo: 'BHR-ST-350_D_1',
    message:
      '이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트',
    createdAt: '2023-06-20 12:00:00',
    isRead: false,
  },
  {
    type: 'ALARM',
    customerId: '2a356da4-f958-477b-bfbf-fdafd955bf43',
    serialNo: 'BHR-ST-350_D_2',
    message:
      '이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트',
    createdAt: '2023-06-25 12:00:00',
    isRead: true,
  },
  {
    type: 'INFO',
    customerId: '9f642323-538e-42b3-a890-71c9a8da08a7',
    serialNo: 'BHR-ST-350_D_1',
    message:
      '이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트',
    createdAt: '2023-06-26 12:00:00',
    isRead: true,
  },
  {
    type: 'ALARM',
    customerId: '359ca038-4aaf-44c3-b51d-ab75963c3baf',
    serialNo: 'BHR-ST-350_D_3',
    message:
      '이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트',
    createdAt: '2023-06-27 12:00:00',
    isRead: false,
  },
  {
    type: 'WARN',
    customerId: '359ca038-4aaf-44c3-b51d-ab75963c3baf',
    serialNo: 'BHR-ST-350_D_3',
    message: '이벤트 메시지 내용입니다.',
    createdAt: '2023-06-23 12:00:00',
    isRead: true,
  },
  {
    type: 'INFO',
    customerId: '359ca038-4aaf-44c3-b51d-ab75963c3baf',
    serialNo: 'BHR-ST-350_D_3',
    message:
      '이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트이벤트 메시지 내용입니다. 2줄 이상일 경우 테스트',
    createdAt: '2023-10-23 14:00:00',
    isRead: false,
  },
];
