import { useMutation } from 'react-query';

import { axiosInstance } from '../../base';

const apiUrl = '/logout';

const postLogout = async () => {
  const response = await axiosInstance.post(apiUrl);
  return response;
};

export const useLogout = () => {
  return useMutation({ mutationFn: postLogout });
};
