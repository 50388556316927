import { Row, Button, Space, Table, Input, Badge, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames/bind';
import { ReloadOutlined } from '@ant-design/icons';

import { ContentsLayout } from '../../../components/layout';
import { useUserInfo } from '../../../hooks/userInfo';
import { useGetAs } from '../../../apis';

import AddNewAsModal from '../modal/addNewAs';
import { columns, asStatusOptions } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const As = () => {
  const navigate = useNavigate();
  const [isAddNewModal, setIsAddNewModal] = useState(false);
  const [search, setSearch] = useState('');
  const [statusType, setStatusType] = useState('ALL');

  const { userInfo } = useUserInfo();

  const { data: asData } = useGetAs();

  return (
    <>
      <ContentsLayout headTitle="A/S내역">
        <div className={cx('container')}>
          <Row align={'middle'} justify={'space-between'}>
            <Space align="center">
              <strong className={cx('search')}>검색</strong>
              <Input
                value={search}
                placeholder="업체명, 일련번호"
                onChange={(onChangeEvent) => setSearch(onChangeEvent.target.value)}
              />
              <Select
                onChange={(value) => setStatusType(value)}
                defaultValue={'ALL'}
                value={statusType}
                style={{ width: 140 }}
                options={asStatusOptions}
              />
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => {
                  setStatusType('ALL');
                  setSearch('');
                }}>
                초기화
              </Button>
            </Space>
            <p className={cx('count')}>
              <Badge status="success" size="large" /> 전체 : {Number(asData?.totalCount).toLocaleString()}
            </p>
          </Row>
          {userInfo.role !== 'ADMIN' && (
            <Row align={'middle'} justify={'end'}>
              <Space>
                <Button size="large" type="primary" onClick={() => setIsAddNewModal((isPrev) => !isPrev)}>
                  A/S 신청
                </Button>
              </Space>
            </Row>
          )}
          <Table
            size="small"
            dataSource={
              asData?.items
                ?.filter((data) => {
                  if (search === '') return data;
                  return data.customer?.name.includes(search) || data.serialNo.includes(search);
                })
                .filter((data2) => {
                  if (statusType === 'ALL') return data2;
                  return data2.status === statusType;
                }) || []
            }
            scroll={{
              x: 1380,
              y: 600,
            }}
            virtual
            rowKey="rid"
            onRow={(record) => {
              return {
                onClick: () => navigate(`/as/detail/${record.rid}`),
              };
            }}
            pagination={false}
            columns={columns}
          />
        </div>
      </ContentsLayout>

      <AddNewAsModal isOpen={isAddNewModal} setIsOpen={setIsAddNewModal} />
    </>
  );
};

export default As;
