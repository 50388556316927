import { Form, Space, Input, Button, Divider, message, Row, Col, Modal } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import { useGetUserDetail, useGetEquipments, usePatchUser, useDeleteUser } from '../../../../apis';
import { EMAIL_REGEXP } from '../../../../constants/reg';
import { ContentsLayout } from '../../../../components/layout';
import { translateUserRole } from '../../../../utils/translateUserRole';
import { useUserInfo } from '../../../../hooks';

import EquipmentTable from './equipmentTable';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const UsersDetail = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [form] = Form.useForm();
  const [equipments, setEquipments] = useState([]);

  const { data: userDetailData } = useGetUserDetail({ rid });
  const { data: equipmentsData } = useGetEquipments();
  const { mutate: patchUser, isLoading: isPatchLoading } = usePatchUser();
  const { mutate: deleteUser } = useDeleteUser();
  const { userInfo } = useUserInfo();

  const onPatchUserDetail = (values) => {
    if (!userDetailData) return;
    patchUser(
      {
        rid: rid,
        role: userDetailData.role,
        name: values.name,
        email: values.email,
        phone: values.phone,
        memo: values.memo,
      },
      {
        onSuccess: (res) => {
          if (!res.success)
            message.error({
              key: 'error',
              content: '수정에 실패했습니다.',
            });

          message.success({
            key: 'success',
            content: '수정완료 되었습니다.',
          });
        },
      },
    );
  };

  const onDeleteUser = () => {
    deleteUser(
      { rid },
      {
        onSuccess: () => {
          navigate(-1);
          message.success('유저가 삭제되었습니다.');
        },
      },
    );
  };

  const resetPassword = () => {
    if (!userDetailData) return;
    patchUser(
      {
        rid: rid,
        role: userDetailData.role,
        name: userDetailData.name,
        email: userDetailData.email,
        phone: userDetailData.phone,
        memo: userDetailData.memo,
        password: '1234',
      },
      {
        onSuccess: (res) => {
          if (!res.success)
            message.error({
              key: 'error',
              content: '변경에 실패했습니다.',
            });

          message.success({
            key: 'success',
            content: '비밀번호가 초기화 되었습니다.',
          });
        },
      },
    );
  };

  useEffect(() => {
    if (userDetailData)
      form.setFieldsValue({
        ...userDetailData,
        role: translateUserRole(userDetailData.role),
        createdByName: userDetailData.createdBy.name,
        updatedAtName: userDetailData.updatedBy.name,
        customerName: userDetailData.customer === null ? 'BTE' : userDetailData.customer.name,
      });
  }, [userDetailData]);

  useEffect(() => {
    if (equipmentsData && userDetailData) {
      const currentEquipment = equipmentsData.filter((data) => data.customerRid === userDetailData.customerRid);
      setEquipments(currentEquipment);
    }
  }, [userDetailData, equipmentsData]);

  return (
    <ContentsLayout
      headTitle="사용자상세"
      buttons={[
        <Button
          danger
          type="primary"
          size="large"
          disabled={userInfo?.role !== 'ADMIN'}
          onClick={() => {
            Modal.confirm({
              title: '정말 삭제하시겠습니까?',
              okText: '네',
              cancelText: '아니오',
              onOk: onDeleteUser,
            });
          }}>
          유저삭제
        </Button>,
        <Button
          size="large"
          disabled={userInfo?.role !== 'ADMIN'}
          onClick={() => {
            Modal.confirm({
              title: '비밀번호를 초기화 하시겠습니까?(초기값 : 1234)',
              okText: '네',
              cancelText: '아니오',
              onOk: resetPassword,
            });
          }}>
          비밀번호 초기화
        </Button>,
      ]}>
      <Form form={form} layout="vertical" onFinish={onPatchUserDetail}>
        <div className={cx('box')}>
          <strong className={cx('subTitle')}>기본 정보</strong>

          <Row align={'top'} justify={'center'} gutter={[24, 24]} wrap={false} style={{ maxWidth: 840 }}>
            <Col flex={'40%'}>
              <Form.Item label="역할" name="role">
                <Input disabled />
              </Form.Item>
              <Form.Item label="소속업체" name="customerName">
                <Input disabled />
              </Form.Item>
              <Form.Item label="아이디" name="id">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col flex={'60%'}>
              <Form.Item label="성명" name="name">
                <Input />
              </Form.Item>
              <Form.Item
                label="이메일"
                name="email"
                rules={[{ pattern: EMAIL_REGEXP, message: '이메일 형식이 아닙니다.' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="전화번호" name="phone">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>

        {userDetailData?.role === 'CUSTOMER' && (
          <EquipmentTable rid={rid} equipments={equipments} userEquipments={userDetailData?.userEquipments} />
        )}

        <Divider />
        <div className={cx('box')}>
          <strong className={cx('subTitle')}>부가 정보</strong>

          <Row align={'top'} wrap={false} justify={'center'} gutter={[24, 24]} style={{ maxWidth: 840 }}>
            <Col flex={'50%'}>
              <Form.Item name="memo" label="메모">
                <Input.TextArea size="large" style={{ minHeight: 150 }} />
              </Form.Item>
            </Col>
            <Col flex={'50%'}>
              <Space.Compact>
                <Form.Item name="createdByName" label="등록자">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>

                <Form.Item name="createdAt" label="등록일시">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>
              </Space.Compact>

              <Space.Compact>
                <Form.Item name="updatedAtName" label="수정자">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>

                <Form.Item name="updatedAt" label="수정일시">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>
              </Space.Compact>
            </Col>
          </Row>
        </div>
        <Row className={cx('fix')} align={'middle'} justify={'space-between'}>
          <Button size="large" type="default" onClick={() => navigate(-1)}>
            뒤로
          </Button>
          <Button size="large" type="primary" htmlType="submit" loading={isPatchLoading} disabled={isPatchLoading}>
            저장
          </Button>
        </Row>
      </Form>
    </ContentsLayout>
  );
};

export default UsersDetail;
