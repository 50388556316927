import { Divider, Row, Col, Table, Button, message } from 'antd';
import classNames from 'classnames/bind';

import { usePostUserEquipment, useDeleteUserEquipment } from '../../../../apis';

import { canAddEquipmentColumns, currentUserEquipmentsColumns } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const EquipmentTable = ({ equipments, rid, userEquipments }) => {
  const { mutate: postUserEquipment } = usePostUserEquipment();
  const { mutate: deleteUserEquipment } = useDeleteUserEquipment();

  return (
    <>
      <Divider />
      <div className={cx('box')}>
        <strong className={cx('subTitle')}>담당 장비</strong>
        <Row align={'top'} justify={'center'} gutter={[24, 24]} wrap={false} style={{ maxWidth: 1440 }}>
          <Col flex={'50%'}>
            <Table
              size="small"
              scroll={{
                x: 600,
              }}
              onRow={(record) => {
                return {
                  onClick: () =>
                    postUserEquipment(
                      {
                        rid: record.rid,
                        equipmentRid: rid,
                      },
                      {
                        onSuccess: (res) => {
                          if (!res.success && res.data.message === 'the user is already assigned equipment.')
                            return message.error('이미 등록된 장비입니다.');

                          if (!res.success) return message.error('등록 실패하였습니다.');

                          return message.success(`${record.serialNo} 장비가 등록되었습니다.`);
                        },
                      },
                    ),
                };
              }}
              caption="추가 가능한 장비목록"
              columns={canAddEquipmentColumns}
              dataSource={equipments}
              rowKey={'rid'}
              pagination={false}
            />
          </Col>
          <Col flex={'50%'}>
            <Table
              size="small"
              scroll={{
                x: 600,
              }}
              caption="추가된 장비목록"
              columns={[
                ...currentUserEquipmentsColumns,
                {
                  title: '제거',
                  dataIndex: 'customerRid',
                  key: 'customerRid',
                  render: (_, record) => (
                    <Button
                      size="small"
                      type="primary"
                      ghost
                      danger
                      onClick={() =>
                        deleteUserEquipment(
                          {
                            equipmentRid: record.equipmentRid,
                            rid: rid,
                          },
                          {
                            onSuccess: (res) => {
                              if (!res.success) return message.error('제거 실패했습니다');
                              return message.success('제거 완료되었습니다');
                            },
                          },
                        )
                      }>
                      제거
                    </Button>
                  ),
                },
              ]}
              dataSource={userEquipments || []}
              rowKey={'equipmentRid'}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EquipmentTable;
