import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { Popover, Menu, Row } from 'antd';
import { FiBell, FiUser } from 'react-icons/fi';

import { useUserInfo } from '../../../hooks';
import { URL_LIST } from '../../../constants/url';
import { MAIN_PAGE } from '../../../constants/url';
import LOGO_IMAGE from '../../../assets/common/logo.png';

import UserInfoComponent from './userInfoComponent';
import styles from './index.module.scss';
import Notifications from './modal/notification';

const cx = classNames.bind(styles);

const HeaderLayout = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const { isCustomer, isAdmin, userInfo } = useUserInfo();

  const ITEMS = URL_LIST.map((data) => {
    const blockingPageList = ['업체관리', 'A/S통계', '로그내역'];
    const blockingPageFromNormalUser = ['사용자관리'];

    if (data.subLink) {
      return {
        label: <Link to={data.link}>{data.title}</Link>,
        icon: data.icon,
        key: data.key,
        children: data.subLink.map((data2) => {
          const isBlockingPage = blockingPageList.some((data3) => data3 === data2.title);
          const isBlockingFromNormalUserPage = blockingPageFromNormalUser.some((data3) => data3 === data2.title);

          if (isBlockingFromNormalUserPage && userInfo?.role === 'SUB-CUSTOMER') return null;
          if (isCustomer && isBlockingPage) return null;
          return {
            label: <Link to={data2.link}>{data2.title}</Link>,
            key: data2.link,
          };
        }),
      };
    }

    if (isCustomer && blockingPageList.some((data4) => data4 === data.title)) return null;

    return {
      label: <Link to={data.link}>{data.title}</Link>,
      icon: data.icon,
      key: data.key,
    };
  });

  useEffect(() => {
    setCurrentPage(location.pathname.slice(1));
  }, [location]);

  return (
    <>
      <header className={cx('header')}>
        <Row align={'middle'} justify={'space-between'} wrap={false}>
          <div className={cx('left')}>
            <Link to={MAIN_PAGE.link} className={cx('logo')} onClick={() => setCurrentPage('/')}>
              <img src={LOGO_IMAGE} alt="" />
            </Link>
            <Menu
              onClick={(onClickEvent) => setCurrentPage(onClickEvent.key)}
              defaultOpenKeys={[currentPage]}
              selectedKeys={[currentPage?.split('/')[0], currentPage]}
              overflowedIndicator={null}
              mode="horizontal"
              items={ITEMS}
              className={cx('menus')}
            />
          </div>

          <p className={cx('userDesc')}>{`${userInfo?.name}님(${userInfo?.customer?.name || 'BTE'})`}</p>

          <Row wrap={false} justify={'end'} align={'middle'} className={cx('right')}>
            {isAdmin && (
              <button
                type="button"
                aria-label="알람 확인하기"
                onClick={() => setIsNotificationOpen((isPrev) => !isPrev)}
                className={cx('btn')}>
                <FiBell size={16} color="#444" />
              </button>
            )}
            <Popover content={<UserInfoComponent />} title={`${userInfo?.name}`}>
              <button type="button" aria-label="유저 정보 확인하기" className={cx('btn')}>
                <FiUser size={16} color="#444" />
              </button>
            </Popover>
          </Row>
        </Row>
      </header>
      <Notifications isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />
    </>
  );
};

export default HeaderLayout;
