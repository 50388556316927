import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { logsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments/status';

const getEquipmentLogs = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(apiUrl, {
    params: {
      ...requestObj,
      from: dayjs(requestObj.from).format('YYYYMMDDHHmmss'),
      to: dayjs(requestObj.to).format('YYYYMMDDHHmmss'),
    },
  });

  const sorted = response.data.sort((a, b) => (dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1));

  return { ...response, data: sorted };
};

export const useGetEquipmentLogs = (requestObj) => {
  return useQuery({
    keepPreviousData: false,
    queryFn: getEquipmentLogs,
    queryKey: logsKey.list(requestObj),
  });
};
