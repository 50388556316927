import { useQuery } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as/stats';

const getAsStats = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(apiUrl, {
    params: requestObj,
  });
  return response;
};

export const useGetAsStats = (requestObj) =>
  useQuery({
    queryFn: getAsStats,
    queryKey: asKey.stats(requestObj),
    enabled: Boolean(requestObj),
    select: (response) => {
      delete response.data.as.CHECK;

      const asSum = Object.values(response.data.as)
        .filter((data) => data !== null && !isNaN(data))
        .reduce((acc, val) => acc + val, 0);

      const meetSum = Object.values(response.data.meet)
        .filter((data) => data !== null && !isNaN(data))
        .reduce((acc, val) => acc + val, 0);

      return [{ ...response.data, asSum, meetSum, key: 'key' }];
    },
  });
