import { Col, Row } from 'antd';
import classNames from 'classnames/bind';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useState } from 'react';

import KEY from '../../constants/googleMapKey';

import Map from './map';
import Items from './items';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const MainPage = () => {
  const [bounds, setBounds] = useState();

  return (
    <Wrapper apiKey={KEY} version="beta" libraries={['geometry', 'marker']} language="ko">
      <div className={cx('container')}>
        <Row>
          <Col flex={1} className={cx('map')}>
            <Map setBounds={setBounds} />
          </Col>
          <Col flex={'420px'} className={cx('items')}>
            <Items bounds={bounds} />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default MainPage;
