import dayjs from 'dayjs';

export const columns = [
  {
    title: '업체명',
    dataIndex: 'name',
    key: 'name',
    width: 80,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    width: 200,
    render: (_, record) => `${record.address} ${record.addressDetail}`,
    sorter: (a, b) => a.address.localeCompare(b.address),
  },
  {
    title: '대표번호',
    dataIndex: 'tel',
    width: 80,
    key: 'tel',
    sorter: (a, b) => Number(a.tel) - Number(b.tel),
  },
  {
    title: '등록자',
    dataIndex: 'rid',
    key: 'rid',
    width: 60,
    render: (_, record) => record.createdBy['name'],
    sorter: (a, b) => a.address.localeCompare(b.address),
  },
  {
    title: '등록일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    sorter: (a, b) => {
      return dayjs(a.createdAt) - dayjs(b.createdAt);
    },
    align: 'center',
  },
  {
    title: '수정자',
    dataIndex: 'rid',
    width: 60,
    key: 'rid',
    sorter: (a, b) => a.address.localeCompare(b.address),
    render: (_, record) => record.updatedBy['name'],
  },
  {
    title: '수정일시',
    dataIndex: 'updatedAt',
    width: 100,
    align: 'center',
    key: 'updatedAt',
    sorter: (a, b) => {
      return dayjs(a.updatedAt) - dayjs(b.updatedAt);
    },
  },
];
