import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';

import { equipmentsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments';

const patchEquipmentComponent = async (requestData) => {
  const response = await axiosInstance.patch(`${apiUrl}/${requestData.rid}/components/${requestData.componentRid}`, {
    period: requestData.period,
    periodType: requestData.periodType,
    inspectionDate: dayjs(requestData.inspectionDate).format('YYYYMMDD'),
  });
  return response;
};

export const usePatchEquipmentComponent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchEquipmentComponent,
    onSuccess: () => {
      queryClient.invalidateQueries(equipmentsKey.all);
    },
  });
};
