import { useMutation, useQueryClient } from 'react-query';

import { customersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/customers';

const patchCustomer = async (requestData) => {
  const response = await axiosInstance.patch(`${apiUrl}/${requestData.rid}`, {
    name: requestData.name,
    address: requestData.address,
    addressDetail: requestData.addressDetail,
    tel: requestData.tel,
    memo: requestData.memo,
  });
  return response;
};

export const usePatchCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries(customersKey.all);
    },
  });
};
