import { useQuery } from 'react-query';

import { componentsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/components';

const getComponents = async () => {
  const response = await axiosInstance.get(apiUrl, {});
  return response;
};

export const useGetComponents = () =>
  useQuery({
    queryFn: getComponents,
    queryKey: componentsKey.all,
    select: (response) => response.data,
  });
