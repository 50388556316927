import dayjs from 'dayjs';

export const canAddEquipmentColumns = [
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
  },
  {
    title: '부스터종류',
    dataIndex: 'boosterType',
    key: 'boosterType',
  },
  {
    title: '부스터최대사용시간',
    dataIndex: 'boosterTime',
    key: 'boosterTime',
    align: 'right',
    render: (_, record) => Number(record.boosterTime).toLocaleString(),
  },
  {
    title: '설치일자',
    dataIndex: 'installedDate',
    key: 'installedDate',
    align: 'center',
    render: (_, record) => dayjs(record.installedDate).format('YYYY-MM-DD'),
  },
  {
    title: '제조일자',
    dataIndex: 'manufacturedDate',
    key: 'manufacturedDate',
    align: 'center',
    render: (_, record) => dayjs(record.manufacturedDate).format('YYYY-MM-DD'),
  },
];

export const currentUserEquipmentsColumns = [
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
    render: (_, record) => record.equipment?.serialNo,
  },
  {
    title: '부스터종류',
    dataIndex: 'boosterType',
    key: 'boosterType',
    render: (_, record) => record.equipment?.serialNo,
  },
  {
    title: '부스터최대사용시간',
    dataIndex: 'boosterTime',
    key: 'boosterTime',
    render: (_, record) => Number(record.equipment?.boosterTime).toLocaleString(),
    align: 'right',
  },
  {
    title: '설치일자',
    dataIndex: 'installedDate',
    key: 'installedDate',
    align: 'center',
    render: (_, record) => dayjs(record.equipment?.installedDate).format('YYYY-MM-DD'),
  },
  {
    title: '제조일자',
    dataIndex: 'manufacturedDate',
    key: 'manufacturedDate',
    align: 'center',
    render: (_, record) => dayjs(record.equipment?.manufacturedDate).format('YYYY-MM-DD'),
  },
];
