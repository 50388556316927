import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Table, Select, Badge, Tag, Form, Row, Button, Space, DatePicker, Checkbox, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { ContentsLayout } from '../../../components/layout';
import { useGetCustomers, useGetEquipments } from '../../../apis';
import { useUserInfo } from '../../../hooks';

import styles from './index.module.scss';
import { columns } from './constant';
import { EVENTS } from '../temp';

const cx = classNames.bind(styles);

const Page = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isChecked, setIsChecked] = useState(false);
  const [type, setType] = useState(null);
  const [filterForm] = Form.useForm();
  const formWatch = Form.useWatch([], filterForm);

  const { data: customers } = useGetCustomers();
  const { data: equipments } = useGetEquipments();

  const { isAdmin, isCustomer, userInfo } = useUserInfo();

  const resetFilter = () => {
    filterForm.setFieldsValue({
      customerId: null,
      serialNo: null,
      period: [dayjs().subtract(10, 'minute'), dayjs()],
    });
    setSearchParams({});
  };

  // 모든 옵션 유효성 검사
  const validate = () => {
    if (dayjs(formWatch?.from).isAfter(dayjs(formWatch?.to))) {
      return '기간을 확인해 주세요.';
    }

    return null;
  };

  // query string 설정
  const searchWithParams = () => {
    const errorMessage = validate();

    if (errorMessage) {
      message.error(errorMessage);
      return;
    }

    const nextSearchParams = {
      customerId: formWatch?.customerId || '',
      serialNo: formWatch?.serialNo || '',
      from: dayjs(formWatch?.period[0]).format('YYYYMMDDHHmmss'),
      to: dayjs(formWatch?.period[1]).format('YYYYMMDDHHmmss'),
    };

    setSearchParams(nextSearchParams);
  };

  // 업체 선택 시 업체 소유 장비 필터링
  const filteredEquipments = useMemo(() => {
    if (equipments === undefined) return [];

    const options = equipments
      .filter((equipment) => equipment.customerRid === formWatch?.customerId)
      .map((d) => ({ label: d.serialNo, value: d.serialNo }));
    return [{ label: '전체', value: null }, ...options];
  }, [equipments, formWatch?.customerId]);

  const tableData = useMemo(() => {
    const id = searchParams.get('customerId');
    const serialNo = searchParams.get('serialNo');
    const from = searchParams.get('from');
    const to = searchParams.get('to');

    return EVENTS.filter((event) => {
      const isOverlapDate = dayjs(event.createdAt).isAfter(dayjs(from)) && dayjs(event.createdAt).isBefore(dayjs(to));
      return (!id || id === event.customerId) && (!serialNo || event.serialNo === serialNo) && isOverlapDate;
    })
      .filter((event) => (isChecked ? !event.isRead : event))
      .map((d, index) => ({ ...d, index }));
  }, [location, isChecked]);

  const customerOptions = useMemo(() => {
    if (!customers) return;
    if (isAdmin) {
      const options = customers.map((d) => ({ label: d.name, value: d.rid }));
      return [{ label: '전체', value: null }, ...options];
    } else {
      return [{ label: userInfo?.customer?.name, value: userInfo?.customer?.rid }];
    }
  }, [isAdmin, customers, userInfo]);

  useEffect(() => {
    filterForm.setFieldsValue({
      customerId: searchParams.get('customerId')
        ? searchParams.get('customerId')
        : isCustomer
        ? userInfo?.customer.rid
        : null,
      serialNo: searchParams.get('serialNo') || null,
      period: [
        searchParams.get('from') ? dayjs(searchParams.get('from')) : dayjs().subtract(10, 'minute'),
        searchParams.get('to') ? dayjs(searchParams.get('to')) : dayjs(),
      ],
    });
  }, [userInfo, isCustomer, searchParams]);

  return (
    <ContentsLayout headTitle="운영로그">
      <div className={cx('container')}>
        <Row align="top" justify="space-between">
          <Form form={filterForm} className={cx('form')}>
            <Space size={18} align="start">
              <Form.Item label="업체" name="customerId" colon={false} className={cx('item')}>
                <Select
                  style={{ width: 180 }}
                  placeholder={!formWatch?.customerId && '선택'}
                  options={customerOptions}
                  onChange={() => {
                    filterForm.setFieldsValue({ serialNo: null });
                  }}
                  className={cx(['field', 'customer'])}
                />
              </Form.Item>
              <Form.Item label="장비" name="serialNo" colon={false} className={cx('item')}>
                <Select
                  style={{ width: 180 }}
                  placeholder={!formWatch?.serialNo && '선택'}
                  options={filteredEquipments}
                  disabled={!formWatch?.customerId || filteredEquipments.length <= 2}
                  className={cx(['field', 'serialNo'])}
                />
              </Form.Item>
              <Form.Item label="기간" name="period" colon={false} className={cx('item')}>
                <DatePicker.RangePicker
                  placeholder={['시작일자', '종료일자']}
                  showTime
                  bordered
                  className={cx('calendar')}
                />
              </Form.Item>
              <Button
                onClick={searchWithParams}
                ghost
                type="primary"
                htmlType="button"
                aria-label="로그내역 조회"
                className={cx('search')}>
                조회
              </Button>
              <Button
                icon={<ReloadOutlined />}
                onClick={resetFilter}
                htmlType="button"
                type="primary"
                className={cx('reset')}>
                새로고침
              </Button>
            </Space>
          </Form>
          <Space onClick={() => setIsChecked(!isChecked)} className={cx('checkbox')}>
            <Checkbox checked={isChecked} />
            <div>미확인 이벤트만 보기</div>
          </Space>
        </Row>
        <ul className={cx('stats')}>
          <li onClick={() => setType(null)} className={cx('column', { active: !type })}>
            <Tag color="#333">전체</Tag> {tableData?.length.toLocaleString()}개
          </li>
          <li onClick={() => setType('INFO')} className={cx('column', { active: type === 'INFO' })}>
            <Tag color="#87d068">정보</Tag> {tableData?.filter((d) => d.type === 'INFO').length.toLocaleString()}개
          </li>
          <li onClick={() => setType('WARN')} className={cx('column', { active: type === 'WARN' })}>
            <Tag color="#f50">경고</Tag> {tableData?.filter((d) => d.type === 'WARN').length.toLocaleString()}개
          </li>
          <li onClick={() => setType('ALARM')} className={cx('column', { active: type === 'ALARM' })}>
            <Tag color="#108ee9">알람</Tag> {tableData?.filter((d) => d.type === 'ALARM').length.toLocaleString()}개
          </li>
        </ul>
        <Table
          size="small"
          dataSource={tableData.filter((d) => (type ? d.type === type : d))}
          columns={columns}
          pagination={false}
          rowKey="key"
          rowId="id"
          virtual
          scroll={{
            x: 1280,
            y: 600,
          }}
        />
      </div>
    </ContentsLayout>
  );
};

export default Page;
