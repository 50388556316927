import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { createRoot } from 'react-dom/client';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Tooltip } from 'antd';

import equipment_img from '../../../../assets/home/marker.png';
import { useMapInstants } from '../../../../hooks';
import { useGetEquipments } from '../../../../apis';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Markers = () => {
  const [param, setParam] = useSearchParams();
  const rid = useMemo(() => param.get('rid'), [param]);

  const { data: equipmentData } = useGetEquipments();
  const { mapInstant: map, changeCenterInfo } = useMapInstants();

  useEffect(() => {
    const markers = equipmentData?.map((data, index) => {
      const content = document.createElement('div');
      createRoot(content).render(
        <div>
          <Tooltip
            title={`${data.customer?.name} : ${data.serialNo}`}
            getPopupContainer={() => document.getElementById(`tooltipContainer${index}`)}>
            <button
              type="button"
              className={cx('marker', {
                select: Boolean(rid === data.rid),
              })}
              aria-label={`${data.serialNo} 자세히보기`}>
              <img src={equipment_img} alt="" />
            </button>
          </Tooltip>
          <div id={`tooltipContainer${index}`} />
        </div>,
      );

      const position = { lng: Number(data.longitude), lat: Number(data.latitude) };

      const marker = new window.google.maps.marker.AdvancedMarkerElement({
        position: position,
        content,
        zIndex: rid === data.rid ? 1500 : 1000,
        map,
        title: data.serialNo,
      });

      marker.addEventListener('gmp-click', () => {
        changeCenterInfo(position);
        setParam({ rid: data.rid });
      });

      return marker;
    });

    new MarkerClusterer({ map, markers });
  }, [equipmentData, map]);

  useEffect(() => {
    //  const content = document.createElement('div');
    // createRoot(content).render(
    //   <div>
    //     <Tooltip
    //       title={`${data.customer?.name} : ${data.serialNo}`}
    //       getPopupContainer={() => tooltipContainerRef.current}>
    //       <button
    //         type="button"
    //         className={cx('marker', {
    //           select: Boolean(rid === data.rid),
    //         })}
    //         aria-label={`${data.serialNo} 자세히보기`}>
    //         <img src={equipment_img} alt="" />
    //       </button>
    //     </Tooltip>
    //     <div ref={tooltipContainerRef} />
    //   </div>,
    // );
    // const ms = equipmentData?.map((data, index)=> {
    //   const label =
    // })
    // if (markerRef.current) {
    //   markerRef.current.setMap(null);
    // }
    // markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
    //   content,
    //   zIndex: rid === data.rid ? 1500 : 1000,
    //   map,
    //   title: data.serialNo,
    //   position: { lat: Number(data.latitude), lng: Number(data.longitude) },
    // });
    // markerRef.current.addEventListener('gmp-click', () => {
    //   changeCenterInfo({ lat: Number(data.latitude), lng: Number(data.longitude) });
    //   setParam({ rid: data.rid });
    // });
  }, [map]);

  return null;
};

export default Markers;
