import {
  Button,
  Form,
  Col,
  Row,
  Space,
  Input,
  Select,
  DatePicker,
  Divider,
  message,
  Tag,
  Radio,
  InputNumber,
  Modal,
} from 'antd';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';

import { ContentsLayout } from '../../../components/layout';
import { useUserInfo } from '../../../hooks/userInfo';
import { useGetAsDetail, usePatchAs, useCancelAs, useDeleteAs } from '../../../apis';

import DoneAsDrawer from '../drawer/doneAs';
import ReportDrawer from '../drawer/report';
import { type, status } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const AsDetail = () => {
  const [form] = Form.useForm();
  const formWatch = Form.useWatch([], form);

  const [isOpen, setIsOpen] = useState(false);
  const { rid } = useParams();
  const navigate = useNavigate();

  const { isAdmin, isCustomer, userInfo } = useUserInfo();

  const { data: asDetailData, refetch: asDetailRefetch } = useGetAsDetail({
    rid,
  });
  const { mutate: patchAs } = usePatchAs();
  const { mutate: postCancelAs } = useCancelAs();
  const { mutate: postDeleteAs } = useDeleteAs();

  const onAsReceipt = () => {
    if (isAdmin)
      patchAs(
        {
          rid,
          status: 'ACCEPT',
        },
        {
          onSuccess: (res) => {
            if (!res.success) return '접수되지 않았습니다.';
            asDetailRefetch();
            return message.success(`${userInfo.name}으로 접수되었습니다.`);
          },
        },
      );
  };

  const onAsPatch = (values) => {
    if (isAdmin && asDetailData?.acceptedBy) {
      patchAs(
        {
          rid,
          visitDate: values.visitDate,
          status: values.status,
          doneByCall: values.doneByCall,
          visitCount: values.visitCount,
          memo: values.memo,
          waitReason: values.waitReason,
        },
        {
          onSuccess: (res) => {
            if (!res.success) return message.error('수정에 실패했습니다.');
            return message.success('수정되었습니다.');
          },
        },
      );
      return;
    }
  };

  const cancelAs = () => {
    postCancelAs(
      { rid },
      {
        onSuccess: (res) => {
          if (!res.success) return message.error('접수가 취소되지않았습니다.');
          return message.success('접수가 취소되었습니다.');
        },
      },
    );
  };

  const deleteAs = () => {
    postDeleteAs(
      { rid },
      {
        onSuccess: (res) => {
          if (!res.success) return message.error('접수가 삭제되지않았습니다.');
          navigate(-1);
          return message.success('삭제 완료되었습니다.');
        },
      },
    );
  };

  useEffect(() => {
    if (asDetailData)
      form.setFieldsValue({
        rid: asDetailData.rid.slice(0, 8),
        serialNo: asDetailData.serialNo,
        customerName: asDetailData.customer?.name,
        requester: asDetailData.createdBy?.name,
        requestDate: dayjs(asDetailData.requestDate).format('YYYY-MM-DD'),
        type: asDetailData.type,
        status: asDetailData.status,
        checkType: asDetailData.checkType,
        visitDate: asDetailData.visitDate ? dayjs(asDetailData.visitDate) : null,
        completedAt: asDetailData.completedAt ? dayjs(asDetailData.completedAt).format('YYYY-MM-DD') : null,
        recipient: asDetailData.acceptedBy?.name,
        recipientDate: asDetailData.acceptedAt ? dayjs(asDetailData.acceptedAt).format('YYYY-MM-DD') : '',
        memo: asDetailData.memo,
        waitReason: asDetailData.waitReason,
        visitCount: asDetailData.visitCount || 0,
        doneByCall: asDetailData.doneByCall || false,
      });
  }, [asDetailData]);

  const IS_DONE = useMemo(() => Boolean(asDetailData?.status === 'DONE'), [asDetailData?.status]);

  return (
    <>
      <ContentsLayout headTitle="A/S상세">
        <Form layout="vertical" form={form} onFinish={onAsPatch}>
          <Row align={'top'} justify={'center'} wrap={false} gutter={[24, 24]} style={{ maxWidth: 840 }}>
            <Col flex={'33.333334%'}>
              <Form.Item label="업체명" name="customerName">
                <Input readOnly className={cx('readOnly')} />
              </Form.Item>

              <Form.Item label="접수번호" name="">
                <p className={cx('field')}>BTE_CS_RE_{dayjs(asDetailData?.createdAt).format('YYYYMMDDHHmmss')}</p>
              </Form.Item>
            </Col>

            <Col flex={'33.333334%'}>
              <Form.Item label="일련번호" name="serialNo">
                <Input readOnly className={cx('readOnly')} />
              </Form.Item>

              <Form.Item label="신청자" name="requester">
                <Input readOnly className={cx('readOnly')} />
              </Form.Item>
            </Col>

            <Col flex={'33.333334%'}>
              <Form.Item label="접수구분" name="type">
                <Select options={type} readOnly className={cx('readOnly')} />
              </Form.Item>

              <Form.Item label="신청일자" name="requestDate">
                <Input readOnly className={cx('readOnly')} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Space>
            <Form.Item label="접수자" name="recipient">
              <Input readOnly className={cx('readOnly')} style={{ width: 120 }} />
            </Form.Item>

            <Form.Item label="접수일자" name="recipientDate">
              <Input readOnly className={cx('readOnly')} style={{ width: 120 }} />
            </Form.Item>

            <Form.Item label="완료일자" name="completedAt">
              <Input readOnly className={cx('readOnly')} style={{ width: 120 }} />
            </Form.Item>

            {asDetailData?.checkType &&
              asDetailData?.checkType.split(',').map((data) => {
                if (data === 'HARDWARE')
                  return (
                    <Tag color="orange" key={'HARDWARE'}>
                      하드웨어
                    </Tag>
                  );
                if (data === 'SOFTWARE')
                  return (
                    <Tag color="orange" key={'SOFTWARE'}>
                      소프트웨어
                    </Tag>
                  );
                if (data === 'HARD/SOFT')
                  return (
                    <Tag color="orange" key={'HARD/SOFT'}>
                      하드/소프트
                    </Tag>
                  );
                if (data === 'ALWAYS')
                  return (
                    <Tag color="orange" key={'ALWAYS'}>
                      정기점검
                    </Tag>
                  );
                return (
                  <Tag color="orange" key={'OTHER'}>
                    기타
                  </Tag>
                );
              })}
          </Space>

          <Divider />
          <Space>
            {!(IS_DONE && !asDetailData?.visitDate) && (
              <Form.Item label="방문예정일자" name="visitDate">
                <DatePicker disabled={isCustomer || IS_DONE} style={{ width: 150 }} />
              </Form.Item>
            )}
            <Form.Item label="AS 방문횟수" name="visitCount">
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="유선완결처리" name="doneByCall">
              <Radio.Group disabled={IS_DONE}>
                <Radio.Button value={true}>처리</Radio.Button>
                <Radio.Button value={false}>미처리</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="진행상황" name="status">
              <Select options={status} disabled={isCustomer || IS_DONE} style={{ width: 150 }} />
            </Form.Item>

            {(formWatch?.status === 'WAIT' || asDetailData?.waitReason) && (
              <Form.Item
                label={asDetailData?.status === 'DONE' ? '이전 대기 사유기록' : '대기사유'}
                name="waitReason"
                rules={[{ required: formWatch?.status === 'WAIT', message: '대기사유를 작성해주세요.' }]}>
                <Input style={{ width: 400 }} readOnly={isCustomer || asDetailData?.status === 'DONE'} />
              </Form.Item>
            )}
          </Space>

          {asDetailData?.status === 'CANCEL' && (
            <>
              <Divider />
              <Space>
                <Form.Item label="취소자" name="">
                  <p className={cx('field')}>{asDetailData?.canceledBy.name}</p>
                </Form.Item>
                <Form.Item label="접수취소일시" name="">
                  <p className={cx('field')}>{dayjs(asDetailData?.canceledDate).format('YYYY-MM-DD HH:mm:ss')}</p>
                </Form.Item>
              </Space>
            </>
          )}

          <Divider />

          <Form.Item label="증상 설명" name="memo">
            <Input.TextArea
              style={{ minHeight: 150, maxWidth: 840 }}
              className={isAdmin && cx('readOnly')}
              readOnly={IS_DONE}
            />
          </Form.Item>

          <Row className={cx('fix')} align={'middle'} justify={'space-between'}>
            <Button type="default" onClick={() => navigate(-1)}>
              뒤로
            </Button>

            <Space>
              {userInfo?.role === 'ADMIN' && (
                <Button
                  type="primary"
                  danger
                  htmlType="button"
                  onClick={() => {
                    Modal.confirm({
                      title: 'AS를 삭제하시겠습니까?',
                      cancelText: '아니오',
                      okText: '네',
                      onOk: deleteAs,
                    });
                  }}>
                  AS삭제
                </Button>
              )}
              {!IS_DONE && asDetailData?.status !== 'CANCEL' && (
                <Button
                  htmlType="button"
                  type="primary"
                  danger
                  onClick={() => {
                    Modal.confirm({
                      title: '접수를 취소하시겠습니까?',
                      cancelText: '아니오',
                      okText: '네',
                      onOk: cancelAs,
                    });
                  }}>
                  접수취소
                </Button>
              )}

              {isCustomer && asDetailData?.status !== 'CANCEL' && (
                <Button
                  type="primary"
                  htmlType="button"
                  disabled={IS_DONE}
                  onClick={() => {
                    patchAs(
                      {
                        rid,
                        memo: formWatch.memo,
                      },
                      {
                        onSuccess: (res) => {
                          if (!res.success) return message.error('수정에 실패했습니다.');
                          return message.success('수정되었습니다.');
                        },
                      },
                    );
                  }}
                  aria-label="사용자 수정">
                  수정
                </Button>
              )}

              {isAdmin && asDetailData?.status !== 'CANCEL' && (
                <Button
                  type="primary"
                  htmlType="submit"
                  ghost
                  aria-label="관리자 수정"
                  onClick={() => {
                    if (!asDetailData?.acceptedBy) onAsReceipt();
                  }}>
                  {asDetailData?.acceptedBy ? '수정' : '접수'}
                </Button>
              )}

              {isAdmin && Boolean(asDetailData?.acceptedBy) && asDetailData?.status !== 'CANCEL' && (
                <Button onClick={() => setIsOpen((isPrev) => !isPrev)} type="primary">
                  {asDetailData?.status === 'DONE' ? 'A/S점검 Report' : 'AS Report'}
                </Button>
              )}
            </Space>
          </Row>
        </Form>
      </ContentsLayout>

      {asDetailData?.status !== 'DONE' && (
        <DoneAsDrawer isOpen={isOpen} setIsOpen={setIsOpen} data={asDetailData || []} />
      )}
      {asDetailData?.status === 'DONE' && (
        <ReportDrawer isOpen={isOpen} setIsOpen={setIsOpen} data={asDetailData || []} />
      )}
    </>
  );
};

export default AsDetail;
