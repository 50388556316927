import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { equipmentsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments';

const getEquipmentDetail = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(`${apiUrl}/${requestObj.rid}`, {});
  return response;
};

export const useGetEquipmentDetail = (requestObj) =>
  useQuery({
    queryFn: getEquipmentDetail,
    queryKey: equipmentsKey.detail(requestObj),
    select: (response) => ({
      ...response.data,
      installedDate: dayjs(response.data.installedDate),
      manufacturedDate: dayjs(response.data.manufacturedDate),
      createdAt: dayjs(response.data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: dayjs(response.data.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      componentInspections: response.data.componentInspections.map((data) => {
        return {
          componentRid: data.componentRid,
          period: data.period,
          periodType: data.periodType === '' ? 'YEARS' : data.periodType === 'YEAR' ? 'YEARS' : data.periodType,
          inspectionDate: data.inspectionDate,
        };
      }),
    }),
  });
