import { Tabs, Table, Button, message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { TEMP_SERIAL_DATA, serialColumns, checkColumns } from './constants';
import { useGetInspections } from '../../../apis';

import AutoReport from '../drawer/AutoReport';
import AsReport from '../drawer/AsReport';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const TabsTable = () => {
  const param = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isAsReport, setIsAsReport] = useState(true);
  const [asRid, setAsRid] = useState(null);

  const { data: inspectionsData } = useGetInspections({
    rid: param?.rid,
  });

  const showDrawer = (type) => {
    setIsOpen(true);
    type === 'C' ? setIsAsReport(false) : setIsAsReport(true);
  };

  return (
    <>
      <Tabs
        items={[
          {
            key: 'serial',
            label: '용기시리얼 정보',
            children: (
              <div className={cx('tableBox')}>
                <Table
                  size="small"
                  columns={serialColumns}
                  dataSource={TEMP_SERIAL_DATA}
                  pagination={false}
                  rowKey={'rid'}
                  virtual
                  scroll={{
                    x: 1280,
                    y: 400,
                  }}
                />
              </div>
            ),
          },
          {
            key: 'inspection',
            label: '점검내역',
            children: (
              <div className={cx('tableBox')}>
                <Table
                  size="small"
                  columns={[
                    ...checkColumns,
                    {
                      title: '리포트',
                      dataIndex: 'report',
                      key: 'report',
                      align: 'center',
                      render: (_, record) => (
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() => {
                            if (!record.asRid && record.type !== 'C') {
                              message.error('리포트가 존재하지않습니다.');
                            }
                            setAsRid(record.asRid);
                            showDrawer(record.type);
                          }}>
                          Report
                        </Button>
                      ),
                    },
                  ]}
                  dataSource={inspectionsData}
                  pagination={false}
                  virtual
                  rowKey={'rid'}
                  scroll={{
                    x: 1280,
                    y: 400,
                  }}
                />
              </div>
            ),
          },
        ]}
      />

      {isAsReport && asRid && <AsReport rid={asRid} open={isOpen} setIsOpen={setIsOpen} />}
      {!isAsReport && <AutoReport open={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};

export default TabsTable;
