import classNames from 'classnames/bind';

import styles from './index.module.scss';
import { Button, Row, Space } from 'antd';
import { Fragment } from 'react';

const cx = classNames.bind(styles);

const ContentsLayout = ({ headTitle, children, buttons }) => {
  return (
    <div className={cx('wrapper')}>
      <Row align={'middle'} justify={'space-between'}>
        <h1 className={cx('headingTitle')}>{headTitle}</h1>
        {buttons && (
          <Space>
            {buttons.map((data, index) => {
              return <Fragment key={index}>{data}</Fragment>;
            })}
          </Space>
        )}
      </Row>
      {children}
    </div>
  );
};

export default ContentsLayout;
