import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useSearchParams, useLocation } from 'react-router-dom';
import { ReloadOutlined } from '@ant-design/icons';
import { Row, Form, Button, Select, DatePicker, Table, Space, message, Typography } from 'antd';
import dayjs from 'dayjs';

import { ContentsLayout } from '../../../components/layout';
import { useGetCustomers, useGetEquipments, useGetEquipmentLogs } from '../../../apis';
import { useUserInfo } from '../../../hooks';

import styles from './index.module.scss';
import { download } from './constant';
import { columns, temp } from './constant';

const cx = classNames.bind(styles);

const Page = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterForm] = Form.useForm();
  const formWatch = Form.useWatch([], filterForm);

  const { data: customers } = useGetCustomers();
  const { data: equipments } = useGetEquipments();
  const {
    data: logs,
    isLoading,
    refetch,
  } = useGetEquipmentLogs({
    serialNo: formWatch?.serialNo,
    customerRid: formWatch?.customerId,
    from: formWatch?.period[0],
    to: formWatch?.period[1],
  });

  const { isAdmin, isCustomer, userInfo } = useUserInfo();

  useEffect(() => {
    const customerId = searchParams.get('customerId');
    const serialNo = searchParams.get('serialNo');
    const from = searchParams.get('from');
    const to = searchParams.get('to');

    if (customerId && serialNo && from && to) {
      refetch();
    }
  }, [location]);

  // 모든 옵션 유효성 검사
  const validate = () => {
    if (dayjs(formWatch?.from).isAfter(dayjs(formWatch?.to))) {
      return '기간을 확인해 주세요.';
    }

    return null;
  };

  const searchWithParams = () => {
    const errorMessage = validate();

    if (errorMessage) {
      message.error(errorMessage);
      return;
    }

    const nextSearchParams = {
      customerId: formWatch.customerId ?? '',
      serialNo: formWatch.serialNo ?? '',
      from: dayjs(formWatch.period[0]).format('YYYYMMDDHHmmss'),
      to: dayjs(formWatch.period[1]).format('YYYYMMDDHHmmss'),
    };

    setSearchParams(nextSearchParams);
    refetch();
  };

  const resetFilter = () => {
    filterForm.setFieldsValue({
      customerId: null,
      serialNo: null,
      period: [dayjs().subtract(10, 'minute'), dayjs()],
    });
    setSearchParams({});
  };

  // 업체 선택 시 장비 필터링
  const filteredEquipments = useMemo(() => {
    if (equipments === undefined) return [];

    const options = equipments
      .filter((equipment) => equipment.customerRid === formWatch?.customerId)
      .map((d) => ({ label: d.serialNo, value: d.serialNo }));
    return [{ label: '전체', value: null }, ...options];
  }, [equipments, formWatch?.customerId]);

  useEffect(() => {
    const isSearch = searchParams.get('isSearch');

    if (isSearch === 'true') {
      setTimeout(() => {
        refetch();
      }, 300);
    }
  }, [location]);

  const customerOptions = useMemo(() => {
    if (!customers) return;
    if (isAdmin) {
      const options = customers.map((d) => ({ label: d.name, value: d.rid }));
      return [{ label: '전체', value: null }, ...options];
    } else {
      return [{ label: userInfo?.customer?.name, value: userInfo?.customer?.rid }];
    }
  }, [userInfo, customers, isAdmin]);

  useEffect(() => {
    filterForm.setFieldsValue({
      customerId: searchParams.get('customerId')
        ? searchParams.get('customerId')
        : isCustomer
        ? userInfo?.customer.rid
        : null,
      serialNo: searchParams.get('serialNo') || null,
      period: [
        searchParams.get('from') ? dayjs(searchParams.get('from')) : dayjs().subtract(10, 'minute'),
        searchParams.get('to') ? dayjs(searchParams.get('to')) : dayjs(),
      ],
    });
  }, [userInfo, isCustomer, searchParams]);

  return (
    <ContentsLayout headTitle="센서로그">
      <div className={cx('container')}>
        <Row algin="middle" justify={'space-between'}>
          <Form form={filterForm} className={cx('form')}>
            <Space align="start" size={18}>
              <Form.Item label="업체" name="customerId" colon={false} className={cx('item')}>
                <Select
                  style={{ width: 180 }}
                  placeholder={!formWatch?.customerId && '선택'}
                  options={customerOptions}
                  onChange={() => {
                    filterForm.setFieldsValue({ serialNo: null });
                  }}
                  className={cx(['field', 'customer'])}
                />
              </Form.Item>
              <Form.Item label="장비" name="serialNo" colon={false} className={cx('item')}>
                <Select
                  style={{ width: 180 }}
                  placeholder={!formWatch?.serialNo && '선택'}
                  options={filteredEquipments}
                  disabled={!formWatch?.customerId || filteredEquipments.length <= 2}
                  className={cx(['field', 'serialNo'])}
                />
              </Form.Item>
              <Form.Item label="기간" name="period" colon={false} className={cx('item')}>
                <DatePicker.RangePicker
                  placeholder={['시작일자', '종료일자']}
                  showTime
                  bordered
                  onChange={([from, to]) => {
                    const duration = dayjs(to).diff(dayjs(from), 'hour', true);

                    if (duration > 1) {
                      message.error('1시간 이내의 기간을 선택해 주세요.');
                      resetFilter();
                    }
                  }}
                  className={cx('calendar')}
                />
              </Form.Item>
              <Button
                onClick={searchWithParams}
                ghost
                loading={isLoading}
                type="primary"
                htmlType="submit"
                aria-label="로그내역 조회"
                className={cx('search')}>
                조회
              </Button>
              <Button
                icon={<ReloadOutlined />}
                onClick={resetFilter}
                htmlType="button"
                type="primary"
                className={cx('reset')}>
                새로고침
              </Button>
            </Space>
          </Form>

          <Button type="primary" disabled={!logs || logs?.data.length === 0} onClick={() => download(logs.data)}>
            다운로드
          </Button>
        </Row>

        <Table
          size="small"
          dataSource={logs?.data.map((data, index) => {
            return { ...data, key: index };
          })}
          columns={columns}
          rowId="id"
          rowKey="key"
          pagination={false}
          virtual
          scroll={{
            x: 3200,
            y: 640,
          }}
        />
        <Typography.Text className={cx('heading')}>센서 알람 통계</Typography.Text>
        <div className={cx('table')}>
          <div className={cx('column')}>
            <p className={cx('title')}>알람코드</p>
            <ul className={cx(['list', 'top'])}>
              {temp.map((d) => (
                <li key={d.code}>{d.code}</li>
              ))}
            </ul>
          </div>
          <div className={cx('column')}>
            <p className={cx('title')}>알람회수</p>
            <ul className={cx('list')}>
              {temp.map((d) => (
                <li key={d.code}>{d.value}</li>
              ))}
            </ul>
          </div>
          <div className={cx('column')}>
            <p className={cx('title')}>총합</p>
            <ul className={cx('list')}>
              <li>10</li>
            </ul>
          </div>
        </div>
      </div>
    </ContentsLayout>
  );
};

export default Page;
