import { useAtom } from 'jotai';

import { mapInstants, centerObj } from '../../jotai/mapInstants';

export const useMapInstants = () => {
  const [mapInstant, setMapInstant] = useAtom(mapInstants);
  const [center, setCenter] = useAtom(centerObj);

  const changeMapInstant = (instant) => {
    setMapInstant(instant);
  };

  const changeCenterInfo = (centerInfo) => {
    setCenter(centerInfo);
  };

  return { mapInstant, changeMapInstant, center, changeCenterInfo };
};
