import { InfoIcon, WarnIcon, AlarmIcon } from '../../../../../assets/notifications';

export const getAlarmType = (type) => {
  switch (type) {
    case 'INFO':
      return '정보';
    case 'WARN':
      return '경고';
    case 'ALARM':
      return '알림';
    default:
      return type;
  }
};

export const ICONS = {
  info: <InfoIcon />,
  warn: <WarnIcon />,
  alarm: <AlarmIcon />,
};
