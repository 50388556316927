import { useMutation, useQueryClient } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as';

const postAs = async (requestData) => {
  const response = await axiosInstance.post(apiUrl, requestData);
  return response;
};

export const usePostAs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postAs,
    onSuccess: () => {
      queryClient.invalidateQueries(asKey.all);
    },
  });
};
