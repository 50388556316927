import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { customersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/customers';

const getCustomers = async () => {
  const response = await axiosInstance.get(apiUrl, {});
  return response;
};

export const useGetCustomers = () =>
  useQuery({
    queryFn: getCustomers,
    queryKey: customersKey.all,
    select: (response) =>
      response.data.map((data) => ({
        ...data,
        createdAt: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        updatedAt: dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      })),
  });
