export const translateType = (type) => {
  if (type === 'ERROR') return '에러발생에 의한 접수';
  if (type === 'WARNING') return '동작이상에 의한 접수';
  if (type === 'CHECK') return '검사/점검 요청에 의한 접수';
  if (type === 'UPDATE') return '개선요청에 의한 접수';
  return '기타 접수';
};

export const translateCheckType = (type) => {
  if (type === 'HARDWARE') return '하드웨어';
  if (type === 'SOFTWARE') return '소프트웨어';
  if (type === 'HARD/SOFT') return '하드/소프트';
  if (type === 'ALWAYS') return '정기점검';
  return '기타';
};
