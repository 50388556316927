import { Row, Button, Space, Table, Select, Form, message } from 'antd';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';

import { ContentsLayout } from '../../../components/layout';
import { useUserInfo } from '../../../hooks';
import { useGetEquipments, useGetCustomers } from '../../../apis';

import { columns, TEMP_DATA } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Charge = () => {
  const [form] = Form.useForm();
  const customerWatch = Form.useWatch('customerRid', form);

  const { isCustomer, userInfo } = useUserInfo();

  const { data: customerData } = useGetCustomers();
  const { data: equipmentsData } = useGetEquipments();

  const customersOption = useMemo(() => {
    const resultData = customerData?.map((data) => ({ label: data.name, value: data.rid })) || [];
    if (isCustomer) return resultData.filter((data) => data.label === userInfo?.customer?.name);
    return [{ label: '전체', value: null }, ...resultData];
  }, [customerData, isCustomer, userInfo]);

  const equipmentsOption = useMemo(() => {
    const resultData =
      equipmentsData
        ?.filter((data) => data.customer?.rid === customerWatch)
        .map((data2) => ({ label: data2.serialNo, value: data2.serialNo })) || [];

    return resultData;
  }, [customerWatch, equipmentsData]);

  useEffect(() => {
    form.setFieldsValue({
      customerRid: isCustomer ? userInfo?.customer?.rid : null,
    });
  }, [isCustomer, userInfo]);

  return (
    <ContentsLayout headTitle="충전이력">
      <div className={cx('container')}>
        <Row align={'middle'} justify={'space-between'}>
          <Form form={form}>
            <Space size={24}>
              <Form.Item name="customerRid" label="업체" style={{ margin: 0 }}>
                <Select
                  options={customersOption}
                  style={{ minWidth: 140 }}
                  onChange={() => form.setFieldValue('serialNo', null)}
                />
              </Form.Item>
              <Form.Item name="serialNo" label="장비" style={{ margin: 0 }}>
                <Select disabled={customerWatch === null} options={equipmentsOption} style={{ minWidth: 140 }} />
              </Form.Item>
            </Space>
          </Form>

          <Button type="primary" onClick={() => message.error('현재는 실제데이터가 없습니다.')}>
            다운로드
          </Button>
        </Row>

        <Table
          size="small"
          dataSource={TEMP_DATA}
          scroll={{
            x: 1280,
            y: 600,
          }}
          virtual
          rowKey="rid"
          pagination={false}
          columns={columns}
        />
      </div>
    </ContentsLayout>
  );
};

export default Charge;
