import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { usersKey } from '../../key';
import { axiosInstance } from '../../base';
import { useUserInfo } from '../../../hooks';

const apiUrl = '/users';

const getUsers = async () => {
  const response = await axiosInstance.get(apiUrl);
  return response;
};

export const useGetUsers = () => {
  const { isCustomer, userInfo } = useUserInfo();

  return useQuery({
    queryFn: getUsers,
    queryKey: usersKey.all,
    select: (response) => {
      const data = response.data;
      const resultData = Array.isArray(data)
        ? data.map((data) => ({
            ...data,
            createdAt: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          }))
        : [];

      if (isCustomer) {
        return resultData.filter((data) => data.customer?.name === userInfo?.customer?.name);
      }

      return resultData;
    },
  });
};
