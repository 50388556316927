import { useMutation, useQueryClient } from 'react-query';
import dayjs from 'dayjs';

import { equipmentsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments';

const deleteEquipment = async (requestData) => {
  const response = await axiosInstance.delete(`${apiUrl}/${requestData.rid}`);
  return response;
};

export const useDeleteEquipment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteEquipment,
    onSuccess: () => {
      queryClient.invalidateQueries(equipmentsKey.all);
    },
  });
};
