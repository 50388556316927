import dayjs from 'dayjs';

export const TEMP_DATA = [
  {
    rid: '1',
    date: dayjs().format('YYYY-MM-DD'),
    chargeStartTime: '10:23',
    chargeEndTime: '14:22',
    chargeClearState: '중단',
    qrNo1: '0000005',
    qrNo2: '0000032',
    charge1: '50bar',
    charge2: '100bar',
    chargeClear1: '100 bar',
    chargeClear2: '200 bar',
    chargeClearEquipmentC: '52',
    playTime: '24',
  },
  {
    rid: '2',
    date: dayjs().format('YYYY-MM-DD'),
    chargeStartTime: '09:23',
    chargeEndTime: '12:32',
    chargeClearState: '완료',
    qrNo1: '0000008',
    qrNo2: '0062235',
    charge1: '40bar',
    charge2: '200bar',
    chargeClear1: '320 bar',
    chargeClear2: '220 bar',
    chargeClearEquipmentC: '32',
    playTime: '54',
  },
];

export const SIDO_LIST = [
  {
    name: '전체',
    alias: '전체',
    subregions: [],
  },
  {
    name: '서울특별시',
    alias: '서울',
    subregions: [
      '종로구',
      '중구',
      '용산구',
      '성동구',
      '광진구',
      '동대문구',
      '중랑구',
      '성북구',
      '강북구',
      '도봉구',
      '노원구',
      '은평구',
      '서대문구',
      '마포구',
      '양천구',
      '강서구',
      '구로구',
      '금천구',
      '영등포구',
      '동작구',
      '관악구',
      '서초구',
      '강남구',
      '송파구',
      '강동구',
    ],
  },
  {
    name: '부산광역시',
    alias: '부산',
    subregions: [
      '중구',
      '서구',
      '동구',
      '영도구',
      '부산진구',
      '동래구',
      '남구',
      '북구',
      '해운대구',
      '사하구',
      '금정구',
      '강서구',
      '연제구',
      '수영구',
      '사상구',
      '기장군',
    ],
  },
  {
    name: '대구광역시',
    alias: '대구',
    subregions: ['중구', '동구', '서구', '남구', '북구', '수성구', '달서구', '달성군'],
  },
  {
    name: '인천광역시',
    alias: '인천',
    subregions: ['중구', '동구', '미추홀구', '연수구', '남동구', '부평구', '계양구', '서구', '강화군', '옹진군'],
  },
  { name: '광주광역시', alias: '광주', subregions: ['동구', '서구', '남구', '북구', '광산구'] },
  { name: '대전광역시', alias: '대전', subregions: ['동구', '중구', '서구', '유성구', '대덕구'] },
  { name: '울산광역시', alias: '울산', subregions: ['중구', '남구', '동구', '북구', '울주군'] },
  { name: '세종특별자치시', alias: '세종', subregions: [] },
  {
    name: '경기도',
    alias: '경기',
    subregions: [
      '수원시',
      '고양시',
      '용인시',
      '성남시',
      '부천시',
      '화성시',
      '안산시',
      '남양주시',
      '안양시',
      '평택시',
      '시흥시',
      '파주시',
      '의정부시',
      '김포시',
      '광주시',
      '광명시',
      '군포시',
      '하남시',
      '오산시',
      '양주시',
      '이천시',
      '구리시',
      '안성시',
      '포천시',
      '의왕시',
      '양평군',
      '여주시',
      '동두천시',
      '가평군',
      '과천시',
      '연천군',
    ],
  },
  {
    name: '강원도',
    alias: '강원',
    subregions: [
      '춘천시',
      '원주시',
      '강릉시',
      '동해시',
      '태백시',
      '속초시',
      '삼척시',
      '홍천군',
      '횡성군',
      '영월군',
      '평창군',
      '정선군',
      '철원군',
      '화천군',
      '양구군',
      '인제군',
      '고성군',
      '양양군',
    ],
  },
  {
    name: '충청북도',
    alias: '충북',
    subregions: [
      '청주시',
      '충주시',
      '제천시',
      '보은군',
      '옥천군',
      '영동군',
      '증평군',
      '진천군',
      '괴산군',
      '음성군',
      '단양군',
    ],
  },
  {
    name: '충청남도',
    alias: '충남',
    subregions: [
      '천안시',
      '공주시',
      '보령시',
      '아산시',
      '서산시',
      '논산시',
      '계룡시',
      '당진시',
      '금산군',
      '부여군',
      '서천군',
      '청양군',
      '홍성군',
      '예산군',
      '태안군',
    ],
  },
  {
    name: '전라북도',
    alias: '전북',
    subregions: [
      '전주시',
      '군산시',
      '익산시',
      '정읍시',
      '남원시',
      '김제시',
      '완주군',
      '진안군',
      '무주군',
      '장수군',
      '임실군',
      '순창군',
      '고창군',
      '부안군',
    ],
  },
  {
    name: '전라남도',
    alias: '전남',
    subregions: [
      '목포시',
      '여수시',
      '순천시',
      '나주시',
      '광양시',
      '담양군',
      '곡성군',
      '구례군',
      '고흥군',
      '보성군',
      '화순군',
      '장흥군',
      '강진군',
      '해남군',
      '영암군',
      '무안군',
      '함평군',
      '영광군',
      '장성군',
      '완도군',
      '진도군',
      '신안군',
    ],
  },
  {
    name: '경상북도',
    alias: '경북',
    subregions: [
      '포항시',
      '경주시',
      '김천시',
      '안동시',
      '구미시',
      '영주시',
      '영천시',
      '상주시',
      '문경시',
      '경산시',
      '군위군',
      '의성군',
      '청송군',
      '영양군',
      '영덕군',
      '청도군',
      '고령군',
      '성주군',
      '칠곡군',
      '예천군',
      '봉화군',
      '울진군',
      '울릉군',
    ],
  },
  {
    name: '경상남도',
    alias: '경남',
    subregions: [
      '창원시',
      '진주시',
      '통영시',
      '사천시',
      '김해시',
      '밀양시',
      '거제시',
      '양산시',
      '의령군',
      '함안군',
      '창녕군',
      '고성군',
      '남해군',
      '하동군',
      '산청군',
      '함양군',
      '거창군',
      '합천군',
    ],
  },
  { name: '제주특별자치도', alias: '제주', subregions: ['제주시', '서귀포시'] },
];

export const columns = [
  {
    title: '일자',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
  },
  {
    title: '시작시각',
    dataIndex: 'chargeStartTime',
    key: 'chargeStartTime',
    align: 'center',
  },
  {
    title: '완료시각',
    dataIndex: 'chargeEndTime',
    key: 'chargeEndTime',
    align: 'center',
  },
  {
    title: '종료상태',
    dataIndex: 'chargeClearState',
    key: 'chargeClearState',
    align: 'center',
  },
  {
    title: '용기QR번호',
    children: [
      {
        title: '1번포트',
        dataIndex: 'qrNo1',
        key: 'qrNo1',
        align: 'center',
      },
      {
        title: '2번포트',
        dataIndex: 'qrNo2',
        key: 'qrNo2',
        align: 'center',
      },
    ],
  },
  {
    title: '시작압력',
    children: [
      {
        title: '1번',
        dataIndex: 'charge1',
        key: 'charge1',
      },
      {
        title: '2번',
        dataIndex: 'charge2',
        key: 'charge2',
      },
    ],
  },
  {
    title: '완료압력',
    children: [
      {
        title: '1번',
        dataIndex: 'chargeClear1',
        key: 'chargeClear1',
      },
      {
        title: '2번',
        dataIndex: 'chargeClear2',
        key: 'chargeClear2',
      },
    ],
  },
  {
    title: '완료장비온도',
    dataIndex: 'chargeClearEquipmentC',
    key: 'chargeClearEquipmentC',
    align: 'center',
  },
  {
    title: '운전가동시각(분)',
    dataIndex: 'playTime',
    key: 'playTime',
    align: 'center',
  },
];
