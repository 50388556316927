import { Space, message, Button, Modal, Form, Input, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useLogout, usePatchUser } from '../../../../apis';
import { useUserInfo } from '../../../../hooks';
import { EMAIL_REGEXP } from '../../../../constants/reg';
import { translateUserRole } from '../../../../utils/translateUserRole';

const UserInfoComponent = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState();
  const [userInfoForm] = Form.useForm();
  const userInfoWatch = Form.useWatch([], userInfoForm);

  const { userInfo, clearUserInfo, updateUserInfo } = useUserInfo();
  const { mutate: postLogout } = useLogout();
  const { mutate: patchUser, isLoading: isPatchLoading } = usePatchUser();

  const onLogout = () => {
    postLogout(
      {},
      {
        onSuccess: () => {
          localStorage.removeItem('@ims/isLoggedInfo');
          clearUserInfo();
          sessionStorage.clear();
          navigate('/login');
        },
      },
    );
  };

  const cancelModal = () => {
    setIsOpen(false);
  };

  const onPostUser = (values) => {
    delete values.checkPassword;
    patchUser(
      { rid: userInfo.rid, ...values },
      {
        onSuccess: (res) => {
          if (!res.success) {
            message.error('변경에 실패하였습니다.');
          } else {
            updateUserInfo({
              ...userInfo,
              memo: values.memo,
              name: values.name,
              email: values.email,
              phone: values.phone,
            });
            message.success('변경이 완료되었습니다.');
            setIsOpen(false);
          }
        },
      },
    );
  };

  useEffect(() => {
    userInfoForm.resetFields();
  }, [isOpen]);

  useEffect(() => {
    if (userInfo) {
      userInfoForm.setFieldsValue({
        name: userInfo.name,
        email: userInfo.email,
        phone: userInfo.phone,
        memo: userInfo.memo,
      });
    }
  }, [userInfo, isOpen]);

  return (
    <>
      <Space direction="vertical">
        <p>
          {userInfo?.customer?.name || 'BTE'} {translateUserRole(userInfo?.role)}
        </p>
        <Space>
          <Button onClick={() => setIsOpen(true)}>내정보변경</Button>
          <Button onClick={onLogout}>로그아웃</Button>
        </Space>
      </Space>

      <Modal
        width={640}
        centered
        title="개인정보변경"
        footer={null}
        maskClosable={false}
        onCancel={cancelModal}
        open={isOpen}>
        <Form form={userInfoForm} layout="vertical" onFinish={onPostUser}>
          <Row justify={'space-between'} align={'top'} gutter={[24, 24]}>
            <Col flex={'50%'}>
              <Form.Item label="소속업체">
                <Input disabled value={userInfo?.customer?.name || 'BTE'} />
              </Form.Item>
              <Form.Item label="역할">
                <Input disabled value={translateUserRole(userInfo?.role)} />
              </Form.Item>
              <Form.Item
                label="이메일"
                name="email"
                rules={[{ pattern: EMAIL_REGEXP, message: '이메일 형식에 맞지않습니다.' }]}>
                <Input type="email" />
              </Form.Item>
              <Form.Item label="비밀번호" name="password">
                <Input
                  type="password"
                  onChange={(e) => {
                    if (e.target.value === '') {
                      userInfoForm.setFieldValue('password', undefined);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col flex={'50%'}>
              <Form.Item label="아이디">
                <Input disabled value={userInfo?.id} />
              </Form.Item>
              <Form.Item label="성명" name="name">
                <Input />
              </Form.Item>
              <Form.Item label="전화번호" name="phone">
                <Input />
              </Form.Item>
              <Form.Item
                label="비밀번호 확인"
                name="checkPassword"
                rules={[
                  { required: userInfoWatch?.password, message: '비밀번호 확인을 해주세요.' },
                  {
                    validator: userInfoWatch?.password !== userInfoWatch?.checkPassword,
                    message: '입력된 비밀번호와 다릅니다.',
                  },
                ]}>
                <Input type="password" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="memo" label="메모">
            <Input.TextArea style={{ minHeight: 150 }} />
          </Form.Item>

          <Button htmlType="submit" type="primary" loading={isPatchLoading} disabled={isPatchLoading}>
            변경하기
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default UserInfoComponent;
