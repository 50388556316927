export const saveAccessToken = (token) => {
  sessionStorage.setItem('token', token);
};

export const getAccessToken = () => {
  const token = sessionStorage.getItem('token');
  return token;
};

export const clearAccessToken = () => {
  sessionStorage.removeItem('token');
};
