import { MdBallot, MdOutlineEventNote, MdBatteryChargingFull, MdOutlineContentPasteSearch } from 'react-icons/md';
import { RiToolsLine } from 'react-icons/ri';
import dayjs from 'dayjs';

export const URL_LIST = [
  {
    title: '장비내역',
    key: 'equipments',
    link: '/equipments/list',
    icon: <MdBallot />,
  },
  {
    title: '충전이력',
    key: 'charge',
    link: '/charge/list',
    icon: <MdBatteryChargingFull />,
  },
  {
    title: 'A/S관리',
    icon: <RiToolsLine />,
    key: 'as',
    subLink: [
      {
        title: 'A/S내역',
        link: '/as/list',
      },
      {
        title: 'A/S통계',
        link: '/as/stats',
      },
    ],
  },
  {
    title: '로그내역',
    icon: <MdOutlineEventNote />,
    key: 'logs',
    subLink: [
      {
        title: '운영로그',
        link: `/logs/operation?from=${dayjs().subtract(10, 'minute').format('YYYYMMDDHHmmss')}&to=${dayjs().format(
          'YYYYMMDDHHmmss',
        )}`,
      },
      {
        title: '센서로그',
        link: `/logs/sensor?from=${dayjs().subtract(10, 'minute').format('YYYYMMDDHHmmss')}&to=${dayjs().format(
          'YYYYMMDDHHmmss',
        )}`,
      },
    ],
  },
  {
    title: '기준정보관리',
    icon: <MdOutlineContentPasteSearch />,
    key: 'management',
    subLink: [
      {
        title: '업체관리',
        link: '/management/customers/list',
      },
      {
        title: '장비관리',
        link: '/management/equipments/list',
      },
      {
        title: '사용자관리',
        link: '/management/users/list',
      },
    ],
  },
];

export const MAIN_PAGE = {
  title: 'BTE',
  link: '/',
};

export const LOGIN_PAGE = {
  title: 'BTE 로그인',
  link: '/login',
};
