import { Spin, Card, Space, Tag, Row, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import classNames from 'classnames/bind';

import { useGetEquipments } from '../../../apis';

import styles from './index.module.scss';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

const cx = classNames.bind(styles);

const Items = ({ bounds }) => {
  const navigate = useNavigate();
  const [param, setParam] = useSearchParams();
  const [search, setSearch] = useState('');
  const rid = useMemo(() => param.get('rid'), [param]);

  const { data: equipmentData } = useGetEquipments();

  const filteringEquipments = useMemo(() => {
    if (rid && equipmentData) {
      return [equipmentData.find((data) => data.rid === rid)];
    } else {
      return equipmentData?.filter((data) => {
        const maxLat = bounds?.north;
        const minLat = bounds?.south;
        const minLng = bounds?.west;
        const maxLng = bounds?.east;
        const lat = Number(data.latitude);
        const lng = Number(data.longitude);

        return lat >= minLat && lat <= maxLat && lng >= minLng && lng <= maxLng;
      });
    }
  }, [param, equipmentData, bounds]);

  return (
    <Spin spinning={!bounds}>
      <div className={cx('container')}>
        <Space size={12}>
          <Input.Search
            placeholder="업체명.일련번호"
            onSearch={(value) => {
              setParam(undefined);
              setSearch(value);
            }}
          />
          <Button
            type="primary"
            ghost
            onClick={() => {
              setParam(undefined);
              setSearch('');
            }}>
            전체
          </Button>
        </Space>

        <Space direction="vertical" style={{ width: '100%' }}>
          {filteringEquipments
            ?.filter((data) => {
              if (search === '') return data;
              return data.serialNo.includes(search) || data.customer?.name.includes(search);
            })
            .map((data2) => {
              return (
                <Card
                  key={data2.rid}
                  title={
                    <Row align={'middle'} justify={'space-between'}>
                      {data2.serialNo}
                      <Tag style={{ margin: 0 }} color="#87d068">
                        READY
                      </Tag>
                    </Row>
                  }
                  onClick={() => {
                    navigate({
                      pathname: '/logs/sensor',
                      search: `?${createSearchParams({
                        customerId: data2.customerRid,
                        serialNo: data2.serialNo,
                        from: dayjs().subtract(1, 'M').format('YYYYMMDDHHmmss'),
                        to: dayjs().format('YYYYMMDDHHmmss'),
                        isSearch: true,
                      })}`,
                    });
                  }}
                  style={{ width: '100%' }}
                  size="small"
                  className={cx('card', {
                    select: Boolean(param.get('rid') === data2.rid),
                  })}>
                  <p>{data2.customer.name}</p>
                </Card>
              );
            })}
        </Space>
      </div>
    </Spin>
  );
};

export default Items;
