import { useQuery } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as';

const getAsDetail = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(`${apiUrl}/${requestObj.rid}`);
  return response;
};

export const useGetAsDetail = (requestObj) =>
  useQuery({
    queryFn: getAsDetail,
    queryKey: asKey.detail(requestObj),
    select: (response) => {
      return {
        ...response.data,
        afterServiceComponents: response.data.afterServiceComponents.map((data) => {
          return {
            ...data,
            memo: data.memo === 'undefined' ? '' : data.memo,
          };
        }),
      };
    },
  });
