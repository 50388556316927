export const checkType = [
  {
    label: '하드웨어',
    value: 'HARDWARE',
  },
  {
    label: '소프트웨어',
    value: 'SOFTWARE',
  },
  {
    label: '하드/소프트',
    value: 'HARD/SOFT',
  },
  {
    label: '정기점검',
    value: 'ALWAYS',
  },
  {
    label: '기타',
    value: 'OTHER',
  },
];

export const columns = [
  {
    title: '점검항목',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  },
];
