import { Form, Space, Input, Button, Divider, message, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useEffect } from 'react';

import { useGetCustomerDetail, usePatchCustomer } from '../../../../apis';
import { ContentsLayout } from '../../../../components/layout';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const CustomersDetail = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [form] = Form.useForm();
  const openPostCodePopup = useDaumPostcodePopup();

  const { data: customerDetailData } = useGetCustomerDetail({ rid });
  const { mutate: patchCustomer } = usePatchCustomer();

  const handleGetAddress = () => {
    openPostCodePopup({
      onComplete: (addressObj) => {
        form.setFieldValue(`address`, addressObj.address);
      },
    });
  };

  const onPatchCustomerDetail = (values) => {
    const { name, address, addressDetail, tel, memo } = values;

    patchCustomer(
      {
        rid,
        name,
        address,
        addressDetail,
        tel,
        memo,
      },
      {
        onSuccess: (res) => {
          if (!res.success)
            message.error({
              key: 'error',
              content: '수정에 실패했습니다.',
            });

          message.success({
            key: 'success',
            content: '수정완료 되었습니다.',
          });
        },
      },
    );
  };

  useEffect(() => {
    if (customerDetailData)
      form.setFieldsValue({
        ...customerDetailData,
        updatedAt: dayjs(customerDetailData.updatedAt).format('YYYY-DD-MM HH:mm:ss'),
        createdAt: dayjs(customerDetailData.createdAt).format('YYYY-DD-MM HH:mm:ss'),
        createdByName: customerDetailData.createdBy.name,
        updatedAtName: customerDetailData.updatedBy.name,
      });
  }, [customerDetailData]);

  return (
    <ContentsLayout headTitle="업체상세">
      <Form form={form} layout="vertical" onFinish={onPatchCustomerDetail}>
        <div className={cx('box')}>
          <strong className={cx('subTitle')}>기본 정보</strong>

          <Space direction="vertical">
            <div className={cx('flexBox')}>
              <Form.Item name="name" label="업체명">
                <Input size="large" style={{ width: 250 }} />
              </Form.Item>
            </div>

            <Space align="center">
              <div className={cx('flexBox')}>
                <Form.Item name="address" label="주소">
                  <Input.Search
                    size="large"
                    style={{ width: 300 }}
                    onSearch={handleGetAddress}
                    onClick={handleGetAddress}
                  />
                </Form.Item>
              </div>

              <Form.Item name="addressDetail" label=" ">
                <Input size="large" style={{ width: 400 }} />
              </Form.Item>
            </Space>

            <div className={cx('flexBox')}>
              <Form.Item name="tel" label="대표번호">
                <Input size="large" style={{ width: 250 }} />
              </Form.Item>
            </div>
          </Space>
        </div>

        <Divider />

        <div className={cx('box')}>
          <strong className={cx('subTitle')}>부가 정보</strong>

          <Row align={'top'} wrap={false} justify={'center'} gutter={[24, 24]} style={{ maxWidth: 840 }}>
            <Col flex={'50%'}>
              <Form.Item name="memo" label="메모">
                <Input.TextArea size="large" style={{ minHeight: 150 }} />
              </Form.Item>
            </Col>
            <Col flex={'50%'}>
              <Space.Compact>
                <Form.Item name="createdByName" label="등록자">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>

                <Form.Item name="createdAt" label="등록일시">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>
              </Space.Compact>

              <Space.Compact>
                <Form.Item name="updatedAtName" label="수정자">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>

                <Form.Item name="updatedAt" label="수정일시">
                  <Input size="large" disabled style={{ width: 200 }} />
                </Form.Item>
              </Space.Compact>
            </Col>
          </Row>
        </div>

        <Row className={cx('fix')} align={'middle'} justify={'space-between'}>
          <Button size="large" type="default" onClick={() => navigate(-1)}>
            뒤로
          </Button>
          <Button size="large" type="primary" htmlType="submit">
            저장
          </Button>
        </Row>
      </Form>
    </ContentsLayout>
  );
};

export default CustomersDetail;
