import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Modal, Button, Empty } from 'antd';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import { getAlarmType, ICONS } from './constant';
import { events } from '../temp';

const cx = classNames.bind(styles);

const AlarmModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const moveToLog = () => {
    setIsOpen(false);
    navigate('/logs/operation');
  };

  return (
    <Modal
      onCancel={() => setIsOpen((isPrev) => !isPrev)}
      title="알림"
      maskClosable={false}
      footer={null}
      width={480}
      open={isOpen}
      className={cx('container')}>
      {events.length === 0 && <Empty />}
      {events.length > 0 && (
        <div className={cx('scrollable')}>
          {events.map((notification, index) => {
            const type = notification.type.toLowerCase();

            return (
              <div key={index} onClick={moveToLog} className={cx('item')}>
                <div className={cx(['line', type])} />
                <div className={cx('type')}>
                  <div className={cx('icon')}>{ICONS[type]}</div>
                  <div className={cx(['text', type])}>{getAlarmType(notification.type)}</div>
                </div>
                <div className={cx('details')}>
                  <div className={cx('message')}>{notification.message}</div>
                  <div className={cx('subDetails')}>
                    <div className={cx('serialNo')}>{notification.serialNo}</div>
                    <div className={cx('createdAt')}>{notification.createdAt}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className={cx('footer')}>
        <Button size="large" type="primary" onClick={moveToLog}>
          내역조회
        </Button>
      </div>
    </Modal>
  );
};

export default AlarmModal;
