import { useMutation, useQueryClient } from 'react-query';

import { usersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/users';

const postUserEquipment = async (requestData) => {
  const response = await axiosInstance.post(`${apiUrl}/${requestData.equipmentRid}/equipments/${requestData.rid}`);
  return response;
};

export const usePostUserEquipment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUserEquipment,
    onSuccess: () => {
      queryClient.invalidateQueries(usersKey.all);
    },
  });
};
