import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useState } from 'react';
import { ConfigProvider } from 'antd';

import { GlobalLayout } from './components/layout';
import { useUserInfo } from './hooks';

import MainPage from './pages/home';
import LoginPage from './pages/login';
import AutoLogin from './pages/autologin';
import ChargePage from './pages/charge/list';
// AS관리
import ASPage from './pages/as/list';
import ASDetail from './pages/as/detail';
import StatPage from './pages/as/stats';
// 장비내역
import EquipmentPage from './pages/equipments/list';
import EquipmentDetailPage from './pages/equipments/detail';
// 로그내역
import SensorLog from './pages/logs/sensor';
import OperationLog from './pages/logs/operation';
// 기준정보관리
import CustomerPage from './pages/management/customers/list';
import CustomerDetailPage from './pages/management/customers/detail';
import EquipmentsPage from './pages/management/equipments/list';
import EquipmentsDetailPage from './pages/management/equipments/detail';
import UserPage from './pages/management/users/list';
import UserDetailPage from './pages/management/users/detail';

function App() {
  const { isAdmin } = useUserInfo();

  const [queryClient] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 15000,
          refetchOnWindowFocus: false,
          keepPreviousData: true,
          retry: 0,
        },
      },
    });
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '',
            },
          }}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <GlobalLayout pageTitle="BTE">
                    {isAdmin ? <MainPage /> : <Navigate to="/equipments/list" />}
                  </GlobalLayout>
                }
              />
              <Route
                path="/login"
                element={
                  <GlobalLayout pageTitle="BTE 로그인">
                    <LoginPage />
                  </GlobalLayout>
                }
              />
              <Route path="/autologin" element={<AutoLogin />} />
              <Route
                path="/charge/list"
                element={
                  <GlobalLayout pageTitle="BTE 충전이력">
                    <ChargePage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/as/list"
                element={
                  <GlobalLayout pageTitle="BTE A/S 관리">
                    <ASPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/as/detail/:rid"
                element={
                  <GlobalLayout pageTitle="BTE A/S 상세">
                    <ASDetail />
                  </GlobalLayout>
                }
              />
              <Route
                path="/as/stats"
                element={
                  <GlobalLayout pageTitle="BTE A/S 통계">
                    <StatPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/equipments/list"
                element={
                  <GlobalLayout pageTitle="BTE 장비내역">
                    <EquipmentPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/equipments/detail/:rid"
                element={
                  <GlobalLayout pageTitle="BTE 장비상세">
                    <EquipmentDetailPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/logs/operation"
                element={
                  <GlobalLayout pageTitle="BTE 운영로그">
                    <OperationLog />
                  </GlobalLayout>
                }
              />
              <Route
                path="/logs/sensor"
                element={
                  <GlobalLayout pageTitle="BTE 센서로그">
                    <SensorLog />
                  </GlobalLayout>
                }
              />
              <Route
                path="/management/customers/list"
                element={
                  <GlobalLayout pageTitle="BTE 업체관리">
                    <CustomerPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/management/customers/detail/:rid"
                element={
                  <GlobalLayout pageTitle="BTE 업체상세">
                    <CustomerDetailPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/management/equipments/list"
                element={
                  <GlobalLayout pageTitle="BTE 장비관리">
                    <EquipmentsPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/management/equipments/detail/:rid"
                element={
                  <GlobalLayout pageTitle="BTE 장비상세">
                    <EquipmentsDetailPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/management/users/list"
                element={
                  <GlobalLayout pageTitle="BTE 사용자관리">
                    <UserPage />
                  </GlobalLayout>
                }
              />
              <Route
                path="/management/users/detail/:rid"
                element={
                  <GlobalLayout pageTitle="BTE 사용자상세">
                    <UserDetailPage />
                  </GlobalLayout>
                }
              />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
