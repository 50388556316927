import {
  Drawer,
  Form,
  Row,
  Col,
  Table,
  Radio,
  Input,
  Divider,
  DatePicker,
  Select,
  Button,
  message,
  Checkbox,
  Upload,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import SignatureCanvas from 'react-signature-canvas';
import classNames from 'classnames/bind';

import { useGetComponents, usePostSuccessAs, useGetUsers } from '../../../../apis';

import { checkType, columns } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const DoneAsDrawer = ({ isOpen, setIsOpen, data }) => {
  const [asSuccessForm] = Form.useForm();
  const formWatch = Form.useWatch([], asSuccessForm);
  const signRef = useRef();

  const { data: usersData, refetch: usersDataRefetch } = useGetUsers();
  const { data: equipmentComponents } = useGetComponents();
  const { mutate: postSuccessAs, isLoading } = usePostSuccessAs();

  const closeDrawer = () => {
    asSuccessForm.resetFields();
    setIsOpen((isPrev) => !isPrev);
  };

  const onPostSuccessAs = (values) => {
    postSuccessAs(
      {
        rid: data.rid,
        checkType: values.checkType.join(','),
        checkMemo: values.checkMemo,
        checkDate: values.checkDate,
        checkedByUserRid: values.checkedByUserRid,
        asComment: values.asComment,
        signature: signRef.current.getTrimmedCanvas().toDataURL('image/png'),
        files: values.files?.fileList.map((file) => file.originFileObj),
        components: equipmentComponents.map((data) => {
          return {
            rid: data.rid,
            ok: formWatch[`${data.rid}_ok`],
            memo: formWatch[`${data.rid}_memo`],
          };
        }),
      },
      {
        onSuccess: (res) => {
          if (!res.success) return message.error('A/S완료에 실패했습니다.');
          closeDrawer();
          return message.success('A/S완료 처리 되었습니다.');
        },
      },
    );
  };

  useEffect(() => {
    usersDataRefetch();
    if (data.afterServiceComponents && data.afterServiceComponents.length !== 0) {
      const afterServiceComponents = data.afterServiceComponents.map((data) => ({
        [`${data.rid}_ok`]: data.ok,
        [`${data.rid}_memo`]: data.memo,
      }));
      const resultObject = {};

      afterServiceComponents.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((key) => {
          resultObject[key] = item[key];
        });
      });
      asSuccessForm.setFieldsValue({
        checkMemo: data.checkMemo,
        checkDate: dayjs(data.checkDate),
        checkType: data.checkType,
        asComment: data.asComment,
        checkedByUserRid: data.checkedBy?.rid,
        ...resultObject,
      });
    }
  }, [data, isOpen]);

  return (
    <Drawer onClose={closeDrawer} open={isOpen} title="A/S 완료" width={640} maskClosable={false}>
      <Form form={asSuccessForm} layout="vertical" onFinish={onPostSuccessAs}>
        <Row align={'top'} justify={'center'} wrap={false} gutter={[24, 24]}>
          <Col flex={'50%'}>
            <Form.Item
              label="점검일자"
              name="checkDate"
              rules={[{ required: true, message: '점검일자를 선택해주세요' }]}>
              <DatePicker disabled={data.status === 'DONE'} />
            </Form.Item>
            <Form.Item
              label="점검구분"
              name="checkType"
              rules={[{ required: true, message: '점검구분을 선택해주세요.' }]}>
              <Checkbox.Group options={checkType} disabled={data.status === 'DONE'} />
            </Form.Item>
          </Col>
          <Col flex={'50%'}>
            <Form.Item
              label="점검자"
              rules={[{ required: true, message: '점검자를 선택해주세요.' }]}
              name="checkedByUserRid">
              <Select
                disabled={data.status === 'DONE'}
                options={usersData
                  ?.filter((data) => data.role === 'ADMIN' || data.role === 'SUB-ADMIN')
                  .map((data2) => ({ label: data2.name, value: data2.rid }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Table
          size="small"
          columns={[
            ...columns,
            {
              title: '적합/부적합',
              dataIndex: 'rid',
              key: 'rid',
              width: 180,
              render: (_, record) => (
                <Form.Item
                  style={{ margin: 0 }}
                  name={`${record.rid}_ok`}
                  rules={[{ required: true, message: '선택필수입니다' }]}>
                  <Radio.Group>
                    <Radio.Button value={true}>적합</Radio.Button>
                    <Radio.Button value={false}>부적합</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              ),
            },
            {
              title: '조치사항',
              dataIndex: 'rid',
              key: 'rid',
              render: (_, record) => (
                <Form.Item style={{ margin: 0 }} name={`${record.rid}_memo`}>
                  <Input />
                </Form.Item>
              ),
            },
          ]}
          rowKey="rid"
          dataSource={equipmentComponents}
          pagination={false}
        />

        {data.status !== 'DONE' ? (
          <Form.Item name="files" label="파일첨부">
            <Upload
              beforeUpload={(file) => {
                if (file.size > 52428800) {
                  message.error('용량이 너무 많습니다. (50Mb 이하)');
                  return true;
                } else {
                  return false;
                }
              }}
              onChange={(info) => {
                if (info.file.status === 'done') {
                  message.success(`${info.file.name}의 등록이 완료되었습니다.`);
                }
              }}>
              <Button icon={<UploadOutlined />}>파일 업로드</Button>
            </Upload>
          </Form.Item>
        ) : (
          <div className={cx('files')}>
            <Typography.Text className={cx('title')}>파일 다운로드</Typography.Text>
            <ul>
              {data.afterServiceFiles.map((file, index) => (
                <li key={index}>
                  <a target="_blank" download={true} href={file.url} rel="noreferrer">
                    파일 {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        <Divider />

        <Form.Item
          label="점검조치내용(서술)"
          name="asComment"
          rules={[{ required: true, message: '향후 계획을 작성해주세요.' }]}>
          <Input.TextArea style={{ minHeight: 150 }} />
        </Form.Item>

        <Form.Item
          label="향후 계획"
          name="checkMemo"
          rules={[{ required: true, message: '향후 계획을 작성해주세요.' }]}>
          <Input.TextArea style={{ minHeight: 150 }} />
        </Form.Item>

        {data.status === 'DONE' ? (
          <Form.Item label="서명" name="sign">
            <img className={cx('signature')} src={data.signature} alt="서명" draggable={false} />
          </Form.Item>
        ) : (
          <Form.Item label="서명" name="sign">
            <SignatureCanvas
              ref={signRef}
              penColor="black"
              canvasProps={{ width: 592, height: 200, className: 'sigCanvas' }}
              backgroundColor="#ddd"
            />
          </Form.Item>
        )}

        <Row align={'middle'} justify={'space-between'}>
          <Button onClick={closeDrawer}>닫기</Button>
          {data.status !== 'DONE' && (
            <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
              A/S 완료
            </Button>
          )}
        </Row>
      </Form>
    </Drawer>
  );
};

export default DoneAsDrawer;
