import { Button } from 'antd';

export const TEMP_SERIAL_DATA = [
  {
    rid: 'uuid1',
    serialNo: 'DMI-CYLINDER-001',
    model: '10.9L 수소용기',
    creator: 'DMI',
    chargeCount: 20,
    maxCharge: 350,
    capacity: '10.9L',
    pressure: 240,
    chargeDate: '2025-12.31',
    type: 'Type4',
  },
  {
    rid: 'uuid2',
    serialNo: 'DMI-CYLINDER-002',
    model: '7L 수소용기',
    creator: 'DMI',
    chargeCount: 15,
    maxCharge: 350,
    capacity: '7L',
    pressure: 270,
    chargeDate: '2025-12.31',
    type: 'Type4',
  },
];

export const serialColumns = [
  {
    title: '제조사',
    dataIndex: 'creator',
    key: 'creator',
  },
  {
    title: '모델명',
    dataIndex: 'model',
    key: 'model',
  },
  {
    title: '용기시리얼',
    dataIndex: 'serialNo',
    key: 'serialNo',
    ellipsis: true,
  },
  {
    title: '충전횟수',
    dataIndex: 'chargeCount',
    key: 'chargeCount',
    render: (_, record) => Number(record.chargeCount).toLocaleString(),
    align: 'right',
  },
  {
    title: '최대충전압력',
    dataIndex: 'maxCharge',
    key: 'maxCharge',
    render: (_, record) => `${record.maxCharge}bar`,
  },
  {
    title: '현재용기압력',
    dataIndex: 'pressure',
    key: 'pressure',
    render: (_, record) => `${record.pressure}bar`,
  },
  {
    title: '용량',
    dataIndex: 'capacity',
    key: 'capacity',
  },
  {
    title: '충전기한',
    dataIndex: 'chargeDate',
    key: 'chargeDate',
  },
  {
    title: '타입',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
  },
];

export const checkColumns = [
  {
    title: '점검일시',
    dataIndex: 'inspectedAt',
    key: 'inspectedAt',
    sorter: (a, b) => a.inspectedAt.localeCompare(b.inspectedAt),
    align: 'center',
  },
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
    render: (_, record) => record.equipment.serialNo,
    sorter: (a, b) => a.equipment.serialNo.localeCompare(b.equipment.serialNo),
  },
  {
    title: '담당자',
    dataIndex: 'manager',
    key: 'manager',
    render: (_, record) => record.equipment.manager,
    sorter: (a, b) => a.equipment.manager.localeCompare(b.equipment.manager),
  },
  {
    title: '점검자',
    dataIndex: 'inspectedBy',
    key: 'inspectedBy',
    render: (_, record) => record.inspectedBy.name,
    sorter: (a, b) => a.inspectedBy.name.localeCompare(b.inspectedBy.name),
  },
  {
    title: '점검형태',
    dataIndex: 'type',
    key: 'type',
    render: (_, record) => {
      if (record.type === 'A') return '이상점검';
      if (record.type === 'B') return '정기점검';
      return '자율점검';
    },
    align: 'center',
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
];
