import { useMutation, useQueryClient } from 'react-query';

import { usersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/users';

const patchUser = async (requestData) => {
  const response = await axiosInstance.patch(`${apiUrl}/${requestData.rid}`, {
    role: requestData.role,
    name: requestData.name,
    email: requestData.email,
    password: requestData.password,
    phone: requestData.phone,
    memo: requestData.memo,
  });
  return response;
};

export const usePatchUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchUser,
    onSuccess: () => {
      queryClient.invalidateQueries(usersKey.all);
    },
  });
};
