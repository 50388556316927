import { useMutation, useQueryClient } from 'react-query';

import { equipmentsKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments';

const patchEquipment = async (requestData) => {
  const response = await axiosInstance.patch(`${apiUrl}/${requestData.rid}`, {
    customerRid: requestData.customerRid,
    boosterMethod: requestData.boosterMethod,
    serialNo: requestData.serialNo,
    model: requestData.model,
    softwareVersion: requestData.softwareVersion,
    latitude: requestData.latitude,
    longitude: requestData.longitude,
    boosterTime: requestData.boosterTime,
    boosterType: requestData.boosterType,
    manufacturedDate: requestData.manufacturedDate,
    installedDate: requestData.installedDate,
    memo: requestData.memo,
    lastCheckDate: requestData.lastCheckDate,
    installAddress: requestData.installAddress,
    manager: requestData.manager,
    no: requestData.no,
    successChargeCount: requestData.successChargeCount,
    checkDday: requestData.checkDday,
    installAddressDetail: requestData.installAddressDetail,
  });
  return response;
};

export const usePatchEquipment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchEquipment,
    onSuccess: () => {
      queryClient.invalidateQueries(equipmentsKey.all);
    },
  });
};
