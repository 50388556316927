import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { inspectionKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/equipments';

const getInspections = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(`${apiUrl}/${requestObj.rid}/inspection`);
  return response;
};

export const useGetInspections = (requestObj) => {
  return useQuery({
    enabled: Boolean(requestObj.rid),
    queryFn: getInspections,
    queryKey: inspectionKey.list(requestObj),
    select: (response) =>
      response.data
        .map((data) => ({
          ...data,
          createdAt: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: dayjs(data.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          inspectedAt: dayjs(data.inspectedAt).format('YYYY-MM-DD HH:mm:ss'),
        }))
        .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()),
  });
};
