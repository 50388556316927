import { useQuery } from 'react-query';

import { userEquipments } from '../../key';
import { axiosInstance } from '../../base';

import { useUserInfo } from '../../../hooks';

const apiUrl = '/equipments/serialnos';

const getUserEquipments = async () => {
  const response = await axiosInstance.get(apiUrl, {});
  return response;
};

export const useGetUserEquipments = () => {
  const { isCustomer } = useUserInfo();

  return useQuery({
    enabled: isCustomer,
    queryFn: getUserEquipments,
    queryKey: userEquipments.all,
    select: (response) => response.data,
  });
};
