import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import { saveAccessToken } from '../utils';
import { useUserInfo } from '../hooks';

const baseURL = 'https://api.h2sms.bte-inc.co.kr';
const axiosInstance = axios.create({ baseURL });

const Page = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { updateUserInfo, updateUserRole } = useUserInfo();

  useEffect(() => {
    const ticket = searchParams.get('ticket') ?? '';

    axiosInstance
      .post('/autologin', { ticket })
      .then(({ data }) => data)
      .then(({ success, data }) => {
        if (success && data.accessToken) {
          saveAccessToken(data.accessToken);
          axiosInstance
            .get(`${baseURL}/users/info`, {
              headers: {
                Authorization: `Bearer ${data.accessToken}`,
              },
            })
            .then(({ data }) => {
              axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
              updateUserInfo(data.data);
              const isAdmin = data.data.role === 'ADMIN' || data.data.role === 'SUB-ADMIN';
              updateUserRole(isAdmin);
              navigate('/', { replace: true });
            });
        }
      });
  }, []);

  return null;
};

export default Page;
