import { Row, Button, Input, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames/bind';

import { useUserInfo } from '../../../../hooks';
import { useGetUsers } from '../../../../apis';
import { ContentsLayout } from '../../../../components/layout';

import AddUserModal from '../modal';
import { columns } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Users = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { data: usersData } = useGetUsers();
  const { userInfo } = useUserInfo();

  return (
    <>
      <ContentsLayout headTitle="사용자관리">
        <div className={cx('container')}>
          <Row align={'middle'} justify={'space-between'}>
            <Space align="center">
              <strong className={cx('search')}>검색</strong>
              <Input
                placeholder="소속명"
                onChange={(onChangeEvent) => {
                  const currentValue = onChangeEvent.target.value;
                  if (currentValue.includes('BTE')) {
                    setSearch('');
                  } else {
                    setSearch(onChangeEvent.target.value);
                  }
                }}
              />
            </Space>

            <Button
              onClick={() => setIsOpen((isPrev) => !isPrev)}
              type="primary"
              disabled={userInfo?.role === 'SUB-CUSTOMER'}>
              신규
            </Button>
          </Row>

          <Table
            size="small"
            dataSource={
              usersData?.filter((data) => {
                if (search === '') return data;
                return data.customer?.name.includes(search);
              }) || []
            }
            scroll={{
              x: 1280,
              y: 600,
            }}
            virtual
            rowKey="rid"
            onRow={(record) => {
              return {
                onClick: () => navigate(`/management/users/detail/${record.rid}`),
              };
            }}
            pagination={false}
            columns={columns}
          />
        </div>
      </ContentsLayout>
      <AddUserModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Users;
