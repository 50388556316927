import { Tag } from 'antd';
import dayjs from 'dayjs';

export const columns = [
  {
    title: '번호',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    width: 60,
  },
  {
    title: '접수번호',
    dataIndex: 'reception',
    key: 'reception',
    width: 250,
  },
  {
    title: '업체명',
    dataIndex: 'customerRid',
    key: 'customerRid',
    ellipsis: true,
    width: 80,
    sorter: (a, b) => a.customerRid.localeCompare(b.customerRid),
    render: (_, record) => record.customer?.name,
  },
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
    ellipsis: true,
    width: 140,
    sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
  },

  {
    title: '진행사항',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (_, record) => {
      if (record.status === 'REQUEST') return <Tag color="#f50">접수중</Tag>;
      if (record.status === 'ACCEPT') return <Tag color="#20b89b">접수완료</Tag>;
      if (record.status === 'PROCESSING') return <Tag color="#52b820">A/S 진행중</Tag>;
      if (record.status === 'WAIT') return <Tag color="#444">A/S 대기</Tag>;
      if (record.status === 'CANCEL') return <Tag color="#870909">접수취소</Tag>;
      return <Tag color="#108ee9">A/S 완료</Tag>;
    },
  },
  {
    title: '접수구분',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
    render: (_, record) => {
      if (record.type === 'CHECK') return '검사/점검요청';
      if (record.type === 'UPDATE') return '개선요청';
      if (record.type === 'ERROR') return '에러발생';
      if (record.type === 'WARNING') return '동작이상';
      return '기타';
    },
  },
  {
    title: '정비구분',
    dataIndex: 'checkType',
    key: 'checkType',
    ellipsis: true,
    width: 180,
    render: (_, record) => {
      const checkTypeArr = record.checkType?.split(',');
      if (!checkTypeArr) return '미정';
      return checkTypeArr
        .map((data) => {
          if (data === 'HARDWARE') return '하드웨어';
          if (data === 'SOFTWARE') return '소프트웨어';
          if (data === 'HARD/SOFT') return '하드/소프트';
          if (data === 'ALWAYS') return '정기점검';
          return '기타';
        })
        .join(', ');
    },
    sorter: (a, b) => a.checkType.localeCompare(b.checkType),
  },
  {
    title: '신청자',
    dataIndex: 'requester',
    key: 'requester',
    sorter: (a, b) => a.requester.localeCompare(b.requester),
    render: (_, record) => record.createdBy?.name,
  },
  {
    title: '신청일자',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 100,
    render: (_, record) => {
      if (record.createdAt === null) return '-';
      return dayjs(record.createdAt).format('YYYY-MM-DD');
    },
    sorter: (a, b) => {
      return dayjs(a.createdAt) - dayjs(b.createdAt);
    },
  },
  {
    title: '방문예정일자',
    dataIndex: 'visitDate',
    key: 'visitDate',
    align: 'center',
    width: 100,
    render: (_, record) => {
      if (record.status === 'DONE' && record.visitDate === null) return '미방문완료';
      if (record.visitDate === null) return '미정';
      return dayjs(record.visitDate).format('YYYY-MM-DD');
    },
    sorter: (a, b) => {
      return dayjs(a.visitDate) - dayjs(b.visitDate);
    },
  },
  {
    title: '완료일자',
    dataIndex: 'completedAt',
    key: 'completedAt',
    align: 'center',
    width: 100,
    render: (_, record) => {
      if (record.completedAt === null) return '미완료';
      return dayjs(record.completedAt).format('YYYY-MM-DD');
    },
    sorter: (a, b) => {
      return dayjs(a.completedAt) - dayjs(b.completedAt);
    },
  },
  {
    title: '접수자',
    dataIndex: 'recipient',
    key: 'recipient',
    sorter: (a, b) => a.recipient.localeCompare(b.recipient),
    render: (_, record) => record.acceptedBy?.name,
  },
];

export const asStatusOptions = [
  {
    label: '전체',
    value: 'ALL',
  },
  {
    label: '접수중',
    value: 'REQUEST',
  },
  {
    label: '접수완료',
    value: 'ACCEPT',
  },
  {
    label: 'A/S진행중',
    value: 'PROCESSING',
  },
  {
    label: 'A/S대기',
    value: 'WAIT',
  },
  {
    label: 'A/S완료',
    value: 'DONE',
  },
];
