import { Col, Form, Space, Input, Row, Button, Divider, message, InputNumber, Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';

import { useGetEquipmentDetail, usePatchEquipment, useGetUsers } from '../../../../apis';
import { integerFormatter } from '../../../../utils';
import { useUserInfo } from '../../../../hooks';
import { ContentsLayout } from '../../../../components/layout';

import ChangeComponents from '../modal/changeComponents';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const EquipmentsDetail = () => {
  const navigate = useNavigate();
  const { rid } = useParams();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);

  const openPostCodePopup = useDaumPostcodePopup();

  const { data: equipmentDetailData } = useGetEquipmentDetail({ rid });
  const { data: usersData } = useGetUsers();
  const { mutate: patchEquipment, isLoading: isPatchLoading } = usePatchEquipment();
  const { isCustomer, userInfo } = useUserInfo();

  const handleGetAddress = () => {
    openPostCodePopup({
      onComplete: (addressObj) => {
        form.setFieldValue(`installAddress`, addressObj.address);
      },
    });
  };

  const onPatchEquipment = (values) => {
    patchEquipment(
      {
        rid,
        customerRid: equipmentDetailData.customerRid,
        boosterMethod: values.boosterMethod,
        serialNo: values.serialNo,
        model: values.model,
        softwareVersion: `${values.majorSoftwareVersion}.${values.minorSoftwareVersion}.${values.patchSoftwareVersion}`,
        latitude: values.latitude,
        longitude: values.longitude,
        boosterTime: values.boosterTime,
        boosterType: values.boosterType,
        manufacturedDate: dayjs(values.manufacturedDate).format('YYYYMMDD'),
        installedDate: dayjs(values.installedDate).format('YYYYMMDD'),
        installAddress: values.installAddress,
        manager: values.manager,
        no: values.no,
        successChargeCount: values.successChargeCount,
        checkDday: values.checkDday,
        installAddressDetail: values.installAddressDetail,
        memo: values.memo,
      },
      {
        onSuccess: (res) => {
          if (!res.success) return message.error('변경에 실패했습니다.');
          return message.success('변경이 완료되었습니다.');
        },
      },
    );
  };

  useEffect(() => {
    if (equipmentDetailData) {
      form.setFieldsValue({
        ...equipmentDetailData,
        name: equipmentDetailData.customer.name,
        installedDate: equipmentDetailData.installedDate,
        manufacturedDate: equipmentDetailData.manufacturedDate,
        createdByName: equipmentDetailData.createdBy.name,
        updatedAtName: equipmentDetailData.updatedBy.name,
        majorSoftwareVersion: equipmentDetailData.softwareVersion.split('.')[0],
        minorSoftwareVersion: equipmentDetailData.softwareVersion.split('.')[1],
        patchSoftwareVersion: equipmentDetailData.softwareVersion.split('.')[2],
        installAddress: equipmentDetailData.installAddress,
        manager: equipmentDetailData.manager,
        no: equipmentDetailData.no,
        successChargeCount: equipmentDetailData.successChargeCount,
        checkDday: equipmentDetailData.checkDday,
        installAddressDetail: equipmentDetailData.installAddressDetail,
      });
    }
  }, [equipmentDetailData]);

  return (
    <>
      <ContentsLayout headTitle={'장비상세'}>
        <Form form={form} layout="vertical" onFinish={onPatchEquipment}>
          <div className={cx('box')}>
            <strong className={cx('subTitle')}>기본정보</strong>

            <Row align={'top'} justify={'center'} gutter={[24, 24]}>
              <Col flex={'33.333334%'}>
                <Form.Item name="name" label="업체명">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="serialNo" label="일련번호" rules={[{ required: true, message: '' }]}>
                  <Input disabled={isCustomer} />
                </Form.Item>
                <Form.Item name="model" label="모델명" rules={[{ required: true, message: '' }]}>
                  <Input disabled={isCustomer} />
                </Form.Item>

                <Space.Compact>
                  <Form.Item name="majorSoftwareVersion" label="Major" rules={[{ required: true, message: '' }]}>
                    <InputNumber disabled={isCustomer} min={0} max={99} />
                  </Form.Item>
                  <Form.Item name="minorSoftwareVersion" label="Minor" rules={[{ required: true, message: '' }]}>
                    <InputNumber disabled={isCustomer} min={0} max={99} />
                  </Form.Item>
                  <Form.Item name="patchSoftwareVersion" label="Patch" rules={[{ required: true, message: '' }]}>
                    <InputNumber disabled={isCustomer} min={0} max={99} />
                  </Form.Item>
                </Space.Compact>

                <div>
                  <Space.Compact>
                    <Form.Item label="위도" name="latitude" rules={[{ required: true, message: '' }]}>
                      <InputNumber disabled={isCustomer} />
                    </Form.Item>
                    <Form.Item label="경도" name="longitude" rules={[{ required: true, message: '' }]}>
                      <InputNumber disabled={isCustomer} />
                    </Form.Item>
                  </Space.Compact>
                </div>
              </Col>
              <Col flex={'33.333334%'}>
                <Form.Item name="boosterMethod" label="부스터 방식" rules={[{ required: true, message: '' }]}>
                  <Select
                    disabled={isCustomer}
                    options={[
                      { label: '에어', value: 'AIR' },
                      { label: '전기/단상 110V', value: 'EV110' },
                      { label: '전기/단상 220V', value: 'EV220' },
                    ]}
                  />
                </Form.Item>

                <Form.Item name="boosterTime" label="부스터 최대 사용 시간" rules={[{ required: true, message: '' }]}>
                  <InputNumber disabled={isCustomer} />
                </Form.Item>

                <Form.Item name="boosterType" label="부스터 종류" rules={[{ required: true, message: '' }]}>
                  <Select
                    disabled={isCustomer}
                    options={[
                      { value: 'Maximator', label: 'Maximator(DLE-30-75)' },
                      { value: 'HII', label: 'HII(HIHPG4-21397)' },
                      { value: 'Haskel', label: 'Haskel(AGT-32/62)' },
                    ]}
                  />
                </Form.Item>

                <Form.Item name="manufacturedDate" label="제조일자">
                  <DatePicker allowClear={false} disabled={isCustomer} />
                </Form.Item>

                <Form.Item name="installedDate" label="설치일자">
                  <DatePicker allowClear={false} disabled={isCustomer} />
                </Form.Item>
              </Col>
              <Col flex={'33.333334%'}>
                <Form.Item label="호기" name="no" rules={[{ required: true, message: '호기를 작성해주세요' }]}>
                  <InputNumber min={1} disabled={isCustomer} />
                </Form.Item>
                <Form.Item
                  name="installAddress"
                  label="설치 주소"
                  rules={[{ required: true, message: '설치 주소를 작성해주세요' }]}>
                  <Input.Search onClick={handleGetAddress} onSearch={handleGetAddress} disabled={isCustomer} />
                </Form.Item>
                <Form.Item name="installAddressDetail" label="상세주소">
                  <Input disabled={isCustomer} />
                </Form.Item>

                <Form.Item name="manager" label="담당자" rules={[{ required: true, message: '담당자를 작성해주세요' }]}>
                  <Select
                    disabled={userInfo?.role === 'SUB-CUSTOMER'}
                    options={usersData
                      ?.filter((user) => user.customer?.name === equipmentDetailData?.customer?.name)
                      .map((data) => ({ label: data.name, value: data.name }))}
                  />
                </Form.Item>

                <Form.Item
                  label="점검카운트(day)"
                  name="checkDday"
                  rules={[{ required: true, message: '점검카운트를 작성해주세요' }]}>
                  <InputNumber step={1} min={1} formatter={integerFormatter} disabled={isCustomer} />
                </Form.Item>

                <Form.Item
                  label="충전완료횟수"
                  name="successChargeCount"
                  rules={[{ required: true, message: '충전완료 횟수를 작성해주세요' }]}>
                  <InputNumber step={1} min={1} formatter={integerFormatter} disabled={isCustomer} />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <strong className={cx('subTitle')}>부가 정보</strong>
            <Row align={'top'} wrap={false} justify={'center'} gutter={[24, 24]} style={{ maxWidth: 840 }}>
              <Col flex={'50%'}>
                <Form.Item name="memo" label="메모">
                  <Input.TextArea disabled={isCustomer} style={{ minHeight: 150 }} />
                </Form.Item>
              </Col>
              <Col flex={'50%'}>
                <Space.Compact>
                  <Form.Item name="createdByName" label="등록자">
                    <Input disabled style={{ width: 100 }} />
                  </Form.Item>

                  <Form.Item name="createdAt" label="등록일시">
                    <Input disabled style={{ width: 200 }} />
                  </Form.Item>
                </Space.Compact>

                <Space.Compact>
                  <Form.Item name="updatedAtName" label="수정자">
                    <Input disabled style={{ width: 100 }} />
                  </Form.Item>

                  <Form.Item name="updatedAt" label="수정일시">
                    <Input disabled style={{ width: 200 }} />
                  </Form.Item>
                </Space.Compact>
              </Col>
            </Row>
          </div>

          <Row className={cx('fix')} align={'middle'} justify={'space-between'}>
            <Button size="large" type="default" onClick={() => navigate(-1)}>
              뒤로
            </Button>
            <Space>
              <Button
                size="large"
                type="primary"
                disabled={equipmentDetailData?.componentInspections.length === 0 || userInfo?.role === 'SUB-CUSTOMER'}
                onClick={() => setIsOpen((isPrev) => !isPrev)}>
                소모품관리
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                isLoading={isPatchLoading}
                disabled={isPatchLoading || userInfo?.role === 'SUB-CUSTOMER'}>
                저장
              </Button>
            </Space>
          </Row>
        </Form>
      </ContentsLayout>

      <ChangeComponents
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        rid={rid}
        components={equipmentDetailData?.componentInspections}
      />
    </>
  );
};

export default EquipmentsDetail;
