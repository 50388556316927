import dayjs from 'dayjs';
import { translateUserRole } from '../../../../utils/translateUserRole';

export const columns = [
  {
    title: '소속',
    dataIndex: 'role',
    key: 'role',
    width: 60,
    render: (_, record) => (record.role === 'ADMIN' ? 'BTE' : record.customer?.name),
    sorter: (a, b) => a.customer?.name.localeCompare(b.customer?.name),
  },
  {
    title: '아이디',
    dataIndex: 'id',
    key: 'id',
    width: 60,
    sorter: (a, b) => a.id.localeCompare(b.id),
  },
  {
    title: '성명',
    dataIndex: 'name',
    key: 'name',
    width: 60,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: '역할',
    dataIndex: 'role',
    key: 'role',
    align: 'center',
    width: 60,
    render: (_, record) => translateUserRole(record.role),
    sorter: (a, b) => a.role.localeCompare(b.role),
  },
  {
    title: '연락처',
    dataIndex: 'phone',
    key: 'phone',
    width: 80,
    sorter: (a, b) => a.phone.localeCompare(b.phone),
  },
  {
    title: '이메일',
    dataIndex: 'email',
    key: 'email',
    width: 100,
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: '등록자',
    dataIndex: 'rid',
    key: 'rid',
    width: 80,
    sorter: (a, b) => a.createdBy['name'].localeCompare(b.createdBy['name']),
    render: (_, record) => record.createdBy['name'],
  },
  {
    title: '등록일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => {
      return dayjs(a.createdAt) - dayjs(b.createdAt);
    },
    align: 'center',
    width: 100,
  },
  {
    title: '수정자',
    dataIndex: 'rid',
    key: 'rid',
    width: 80,
    sorter: (a, b) => a.updatedBy['name'].localeCompare(b.updatedBy['name']),
    render: (_, record) => record.updatedBy['name'],
  },
  {
    title: '수정일시',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    sorter: (a, b) => {
      return dayjs(a.updatedAt) - dayjs(b.updatedAt);
    },
    align: 'center',
    width: 100,
  },
];
