import { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import classNames from 'classnames/bind';

import { useMapInstants } from '../../../hooks';
import { baseURL } from '../../../apis/base';
import { getAccessToken } from '../../../utils';

import Markers from '../components/markers';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Map = ({ setBounds }) => {
  const mapRef = useRef(null);
  const [isMapLoad, setIsMapLoad] = useState(false);
  const [myCenter, setMyCenter] = useState();
  const [param, setParam] = useSearchParams();
  const rid = useMemo(() => param.get('rid'), [param]);

  const { changeMapInstant, center } = useMapInstants();

  useEffect(() => {
    const as = async () => {
      let ip;
      const token = getAccessToken();
      await axios.post('https://jsonip.com').then((res) => (ip = res.data.ip));
      await axios
        .get(`${baseURL}/proxy/ipinfo/${ip}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { latitude, longitude } = res.data.data;
          setMyCenter({ lng: longitude, lat: latitude });
        });
    };
    as();
  }, []);

  useEffect(() => {
    if (myCenter) {
      const googleMap = new window.google.maps.Map(mapRef.current, {
        mapId: 'MAP_ID',
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        center: center || myCenter,
        zoom: rid ? 18 : 8,
        clickable: false,
        clickableIcons: false,
        language: 'ko',
        maxZoom: 18,
        minZoom: 5,
        disableDefaultUI: false,
        draggableCursor: 'default',
        zoomControl: true,
      });
      changeMapInstant(googleMap);
      setIsMapLoad(true);

      googleMap.addListener('idle', () => {
        setBounds(googleMap.getBounds().toJSON());
      });
      googleMap.addListener('center_changed', () => {
        setBounds(googleMap.getBounds().toJSON());
      });
      googleMap.addListener('zoom_changed', () => {
        if (rid) setParam('');
      });
    }
  }, [mapRef, center, myCenter]);

  return (
    <div className={cx('container')}>
      <div ref={mapRef} className={cx('map')} />
      {isMapLoad && <Markers />}
    </div>
  );
};

export default Map;
