import dayjs from 'dayjs';

export const columns = [
  {
    title: '업체명',
    dataIndex: 'name',
    key: 'name',
    width: 80,
    render: (_, record) => record.customer.name,
    sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
  },
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    width: 80,
    key: 'serialNo',
    sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
  },
  {
    title: '모델명',
    dataIndex: 'model',
    width: 80,
    key: 'model',
    sorter: (a, b) => a.model.localeCompare(b.model),
  },
  {
    title: '버전',
    dataIndex: 'softwareVersion',
    width: 60,
    align: 'center',
    key: 'softwareVersion',
    sorter: (a, b) => a.softwareVersion.localeCompare(b.softwareVersion),
  },
  {
    title: '부스터종류',
    dataIndex: 'boosterType',
    width: 100,
    ellipsis: true,
    key: 'boosterType',
    sorter: (a, b) => a.boosterType.localeCompare(b.boosterType),
  },
  {
    title: '부스터방식',
    dataIndex: 'boosterMethod',
    width: 60,
    key: 'boosterMethod',
    sorter: (a, b) => a.boosterMethod.localeCompare(b.boosterMethod),
  },
  {
    title: '부스터최대사용시간',
    dataIndex: 'boosterTime',
    width: 60,
    align: 'right',
    key: 'boosterTime',
    sorter: (a, b) => Number(a.boosterTime) - Number(b.boosterTime),
    render: (_, record) => Number(record.boosterTime).toLocaleString(),
  },
  {
    title: '제조일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    width: 100,
    sorter: (a, b) => {
      return dayjs(a.createdAt) - dayjs(b.createdAt);
    },
  },
  {
    title: '설치등록일시',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    width: 100,
    sorter: (a, b) => {
      return dayjs(a.updatedAt) - dayjs(b.updatedAt);
    },
  },
];
