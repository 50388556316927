import { Modal, Form, Input, Row, Col, Button, message, Select } from 'antd';
import { useEffect } from 'react';

import { usePostUser, useGetCustomers } from '../../../../apis';
import { EMAIL_REGEXP } from '../../../../constants/reg';
import { useUserInfo } from '../../../../hooks';

const AddUserModal = ({ isOpen, setIsOpen }) => {
  const [addUserForm] = Form.useForm();
  const userFormWatch = Form.useWatch([], addUserForm);

  const { userInfo, isCustomer } = useUserInfo();

  const { data: customerData } = useGetCustomers();
  const { mutate: postAddUser, isLoading } = usePostUser();

  const onPostUser = (values) => {
    delete values.checkPassword;
    postAddUser(values, {
      onSuccess: (res) => {
        if (!res.success) {
          if (res.data.error.response.data.message.includes('Duplicate entry')) {
            message.error('중복된 아이디가 존재합니다.');
          } else {
            message.error('등록에 실패하였습니다.');
          }
        } else {
          message.success(`${values.name}님의 등록이 완료되었습니다.`);
          setIsOpen((isPrev) => !isPrev);
        }
      },
    });
  };

  useEffect(() => {
    addUserForm.resetFields();
  }, [isOpen]);

  return (
    <Modal
      onCancel={() => setIsOpen((isPrev) => !isPrev)}
      title="사용자 등록"
      maskClosable={false}
      footer={null}
      width={640}
      centered
      open={isOpen}>
      <Form
        layout="vertical"
        form={addUserForm}
        onFinish={onPostUser}
        initialValues={{
          customerRid: isCustomer ? userInfo.customer?.rid : undefined,
        }}>
        <Row align={'top'} justify={'center'} gutter={[24, 24]} wrap={false}>
          <Col flex={'50%'}>
            <Form.Item name="role" label="역할" rules={[{ required: true, message: '역할을 선택해주세요' }]}>
              <Select
                options={[
                  {
                    label: '관리자',
                    value: 'SUB-ADMIN',
                    disabled: userInfo?.role !== 'ADMIN',
                  },
                  {
                    label: '유저관리자',
                    value: 'CUSTOMER',
                    disabled: isCustomer,
                  },
                  {
                    label: '유저',
                    value: 'SUB-CUSTOMER',
                    disabled: userInfo?.role === 'SUB-CUSTOMER',
                  },
                ]}
                onChange={(value) => {
                  if (value === 'SUB-ADMIN') {
                    addUserForm.setFieldValue('customerRid', customerData?.find(({ name }) => name === 'BTE').rid);
                  } else if (isCustomer) {
                    addUserForm.setFieldValue('customerRid', userInfo.customer?.rid);
                  } else {
                    addUserForm.setFieldValue('customerRid', undefined);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="customerRid"
              label="소속 업체"
              rules={[{ required: true, message: '소속 업체를 선택해주세요' }]}>
              <Select
                disabled={userFormWatch?.role === 'SUB-ADMIN' || isCustomer}
                options={customerData?.map((data) => {
                  return {
                    label: data.name,
                    value: data.rid,
                    disabled: data.name === 'BTE' && userFormWatch?.role !== 'SUB-ADMIN',
                  };
                })}
              />
            </Form.Item>
            <Form.Item name="name" label="성명" rules={[{ required: true, message: '성명을 작성해주세요' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="이메일"
              rules={[
                { required: true, message: '이메일을 작성해주세요' },
                { pattern: EMAIL_REGEXP, message: '이메일 형식에 맞지않습니다.' },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item name="phone" label="전화번호" rules={[{ required: true, message: '전화번호를 작성해주세요' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col flex={'50%'}>
            <Form.Item name="id" label="아이디" rules={[{ required: true, message: '아이디를 입력해주세요' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="비밀번호"
              rules={[{ required: true, message: '비밀번호를 작성해주세요' }]}>
              <Input type="password" />
            </Form.Item>
            <Form.Item
              name="checkPassword"
              label="비밀번호 확인"
              rules={[
                { required: true, message: '비밀번호 확인을 작성해주세요' },
                {
                  validator: userFormWatch?.password !== userFormWatch?.checkPassword,
                  message: '입력된 비밀번호와 다릅니다.',
                },
              ]}>
              <Input type="password" />
            </Form.Item>
            <Form.Item name="memo" label="메모">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row align={'middle'} justify={'space-between'}>
          <Button type="primary" ghost onClick={() => setIsOpen((isOpen) => !isOpen)}>
            취소
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            저장
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
