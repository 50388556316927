import { useMemo } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { userInfoAtom, userRole } from '../../jotai/userInfo';

const storageUserInfo = atomWithStorage('userInfo', userInfoAtom);
const storageRole = atomWithStorage('role', userRole);

export const useUserInfo = () => {
  const [userInfo, setUserInfo] = useAtom(storageUserInfo);
  const [isAdmin, setIsAdmin] = useAtom(storageRole);

  const updateUserRole = (role) => {
    setIsAdmin(role);
  };

  const updateUserInfo = (userData) => {
    setUserInfo(userData);
  };

  const clearUserInfo = () => {
    setUserInfo(null);
  };

  const isCustomer = useMemo(() => {
    if (userInfo) {
      return Boolean(userInfo.role === 'CUSTOMER') || Boolean(userInfo.role === 'SUB-CUSTOMER');
    }
    return null;
  }, [userInfo]);

  return {
    userInfo,
    updateUserRole,
    updateUserInfo,
    clearUserInfo,
    isAdmin,
    isCustomer,
  };
};
