import { useMutation, useQueryClient } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as';

const postSuccessAs = async (requestData) => {
  const formData = new FormData();
  if (requestData.files && requestData.files.length !== 0) {
    requestData.files.forEach((file) => {
      formData.append('files', file);
    });
  }
  formData.append('checkType', requestData.checkType);
  formData.append('checkMemo', requestData.checkMemo);
  formData.append('checkDate', requestData.checkDate);
  formData.append('checkedByUserRid', requestData.checkedByUserRid);
  formData.append('asComment', requestData.asComment);
  formData.append('signature', requestData.signature);

  requestData.components.forEach((compo, index) => {
    formData.append(`components[${index}][rid]`, compo.rid);
    formData.append(`components[${index}][ok]`, compo.ok);
    formData.append(`components[${index}][memo]`, compo.memo);
  });

  const response = await axiosInstance.post(`${apiUrl}/${requestData.rid}/done`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
};

export const usePostSuccessAs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postSuccessAs,
    onSuccess: () => {
      queryClient.invalidateQueries(asKey.all);
    },
  });
};
