import dayjs from 'dayjs';

const getDday = (date, count) => {
  if (date && count) {
    const lastCheckDate = dayjs(date);
    const today = dayjs();

    const nextCheckupDate = lastCheckDate.add(count, 'day');
    const dDay = nextCheckupDate.diff(today, 'day');
    return dDay;
  }
};

export default getDday;
