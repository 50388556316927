import classNames from 'classnames/bind';
import { Button, Row, Space, Form, DatePicker, Select, Table, message } from 'antd';
import { ReloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import * as xlsx from 'xlsx';

import { useGetAsStats, useGetCustomers, useGetEquipments } from '../../../apis';
import { ContentsLayout } from '../../../components/layout';

import Charts from './charts';
import styles from './index.module.scss';
import { columns, ALARM_DUMMY, alarmColumns } from './constant';
import { useMemo } from 'react';

const cx = classNames.bind(styles);

const Stats = () => {
  const [form] = Form.useForm();
  const formWatch = Form.useWatch([], form);

  const { data: customerData } = useGetCustomers();
  const { data: equipmentsData } = useGetEquipments();
  const { data: asStatusData } = useGetAsStats({
    startDate: dayjs(formWatch?.date?.[0])?.format('YYYYMMDD'),
    endDate: dayjs(formWatch?.date?.[1])?.format('YYYYMMDD'),
    serialNo: formWatch?.serialNo,
    customerRid: formWatch?.customer,
  });

  const customerList = useMemo(() => {
    const customers = customerData?.map((data) => ({ label: data.name, value: data.rid })) || [];

    return [{ label: '전체', value: null }, ...customers];
  }, [customerData]);

  const equipmentsList = useMemo(() => {
    const equipments =
      equipmentsData
        ?.filter((data) => data.customer?.rid === formWatch?.customer)
        .map((data2) => ({ label: data2.serialNo, value: data2.serialNo })) || [];

    if (equipments.length === 0) {
      return equipments;
    }

    return [{ label: '전체', value: null }, ...equipments];
  }, [equipmentsData, formWatch?.customer]);

  const excelDownload = () => {
    if (!asStatusData) {
      message.error('통계자료가 존재하지않습니다.');
    } else {
      const asArray = Object.values(asStatusData[0].as);
      const meetArray = Object.values(asStatusData[0].meet);

      const excel = xlsx.utils.book_new();
      const ws = xlsx.utils.aoa_to_sheet([
        ['A/S접수구분', '', '', '', '', '점검구분'],
        [
          '에러발생',
          '동작이상',
          '점검요청',
          '개선요청',
          '기타',
          '하드웨어',
          '소프트웨어',
          '소프트/하드',
          '정기점검',
          '기타',
          '방문횟수',
        ],
        [...asArray, ...meetArray, asStatusData[0].totalVisitCount],
      ]);
      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }, // A1 to E1
        { s: { r: 0, c: 5 }, e: { r: 0, c: 9 } }, // F1 to J1
      ];

      const customerName = customerList?.find(({ value }) => value === formWatch?.customer).label;
      xlsx.utils.book_append_sheet(excel, ws, 'AS통계');
      xlsx.writeFile(
        excel,
        `AS통계_${customerName}_${formWatch?.serialNo ? formWatch?.serialNo : '전체'}_${dayjs(
          formWatch?.date?.[0],
        )?.format('YYYYMMDD')}_${dayjs(formWatch?.date?.[1])?.format('YYYYMMDD')}.xlsx`,
      );
    }
  };

  return (
    <ContentsLayout headTitle="A/S통계">
      <div className={cx('container')}>
        <Form
          form={form}
          initialValues={{
            date: [dayjs().subtract(1, 'year'), dayjs()],
            customer: null,
          }}>
          <Row justify={'space-between'} align={'top'}>
            <Space size={'middle'} align="top">
              <Form.Item label="업체" name="customer" colon={false}>
                <Select
                  style={{ width: 120 }}
                  options={customerList}
                  onChange={() => form.setFieldValue('serialNo', null)}
                />
              </Form.Item>
              <Form.Item label="장비" name="serialNo" colon={false}>
                <Select disabled={formWatch?.customer === null} style={{ width: 160 }} options={equipmentsList} />
              </Form.Item>
              <Form.Item label="접수일자" name="date" colon={false} className={cx('field')}>
                <DatePicker.RangePicker placeholder={['시작일자', '종료일자']} bordered className={cx('calender')} />
              </Form.Item>
              <Form.Item>
                <Button icon={<ReloadOutlined />} htmlType="button" type="primary" onClick={() => form.resetFields()}>
                  초기화
                </Button>
              </Form.Item>
            </Space>

            <Button disabled={!asStatusData} type="primary" onClick={excelDownload} icon={<FileExcelOutlined />}>
              다운로드
            </Button>
          </Row>
        </Form>
        <Table
          style={{ maxWidth: 1280 }}
          size="small"
          dataSource={asStatusData || []}
          columns={columns}
          rowKey="key"
          pagination={false}
          scroll={{
            x: 1380,
            y: 600,
          }}
        />

        {asStatusData && <Charts data={asStatusData} />}

        <Table
          style={{ maxWidth: 1280 }}
          size="small"
          dataSource={ALARM_DUMMY}
          columns={alarmColumns}
          rowKey="key"
          pagination={false}
          scroll={{
            x: 1280,
            y: 600,
          }}
        />
      </div>
    </ContentsLayout>
  );
};

export default Stats;
