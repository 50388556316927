import { Modal, Form, Input, Space, Row, Button, message } from 'antd';
import { useDaumPostcodePopup } from 'react-daum-postcode';

import { usePostCustomer } from '../../../../apis';

const AddCustomerModal = ({ isOpen, setIsOpen }) => {
  const [addCustomerForm] = Form.useForm();

  const openPostCodePopup = useDaumPostcodePopup();
  const { mutate: postAddCustomer, isLoading } = usePostCustomer();

  const onPostCustomer = (values) => {
    postAddCustomer(values, {
      onSuccess: (res) => {
        if (!res.success) return message.error('등록에 실패하였습니다.');
        setIsOpen((isPrev) => !isPrev);
        return message.success(`${values.name} 등록이 완료되었습니다.`);
      },
    });
  };

  const handleGetAddress = () => {
    openPostCodePopup({
      onComplete: (addressObj) => {
        addCustomerForm.setFieldValue(`address`, addressObj.address);
      },
    });
  };

  return (
    <Modal
      onCancel={() => setIsOpen((isPrev) => !isPrev)}
      title="업체등록"
      maskClosable={false}
      footer={null}
      width={640}
      centered
      open={isOpen}>
      <Form layout="vertical" form={addCustomerForm} onFinish={onPostCustomer}>
        <Form.Item name="name" label="업체명" rules={[{ required: true, message: '업체명을 작성해주세요' }]}>
          <Input size="large" />
        </Form.Item>
        <Space>
          <Form.Item name="address" label="주소" rules={[{ required: true, message: '주소를 작성해주세요' }]}>
            <Input.Search size="large" onClick={handleGetAddress} onSearch={handleGetAddress} />
          </Form.Item>
          <Form.Item name="addressDetail" label=" ">
            <Input size="large" />
          </Form.Item>
        </Space>
        <Form.Item name="tel" label="대표번호" rules={[{ required: true, message: '대표번호를 작성해주세요' }]}>
          <Input size="large" />
        </Form.Item>
        <Form.Item name="memo" label="메모">
          <Input.TextArea size="large" />
        </Form.Item>
        <Row align={'middle'} justify={'space-between'}>
          <Button type="primary" ghost onClick={() => setIsOpen((isOpen) => !isOpen)}>
            닫기
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
            저장
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddCustomerModal;
