import { Tag } from 'antd';

import Dday from '../util';

export const columns = [
  {
    title: '업체명',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => record.customer.name,
    sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    width: 120,
  },
  {
    title: '일련번호',
    dataIndex: 'serialNo',
    key: 'serialNo',
    width: 120,
    sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
  },
  {
    title: '호기',
    dataIndex: 'no',
    key: 'no',
    width: 60,
    align: 'center',
    sorter: (a, b) => Number(a.no) - Number(b.no),
  },
  {
    title: '설치장소',
    dataIndex: 'installAddress',
    key: 'installAddress',
    width: 200,
    ellipsis: true,
    render: (_, record) => `${record.installAddress} ${record.installAddressDetail}`,
    sorter: (a, b) => a.installAddress.localeCompare(b.installAddress),
  },
  {
    title: '담당자',
    dataIndex: 'manager',
    width: 100,
    key: 'manager',
    sorter: (a, b) => a.manager.localeCompare(b.manager),
  },
  {
    title: '충전완료회수',
    dataIndex: 'successChargeCount',
    key: 'successChargeCount',
    width: 100,
    align: 'right',
    sorter: (a, b) => Number(a.successChargeCount) - Number(b.successChargeCount),
    render: (_, record) => Number(record.successChargeCount).toLocaleString(),
  },
  {
    title: '점검카운트',
    dataIndex: 'checkDday',
    key: 'checkDday',
    width: 80,
    render: (_, record) => {
      const dday = Dday(record?.lastCheckDate, record?.checkDday);
      const day = Math.sign(dday);

      if (day === 1) return <Tag color="#87d068">Dday {dday}</Tag>;
      if (day === -1) return <Tag color="red">Dday {dday}</Tag>;
      return <Tag color="blue">Dday {dday}</Tag>;
    },
    sorter: (a, b) => Number(a.checkDday) - Number(b.checkDday),
  },
  {
    title: '사용누적시간',
    dataIndex: 'useTime',
    width: 100,
    key: 'useTime',
    align: 'right',
    render: (_, record) => Number(record.useTime).toLocaleString(),
    sorter: (a, b) => Number(a.useTime) - Number(b.useTime),
  },
  {
    title: '상태',
    dataIndex: 'rid',
    key: 'rid',
    width: 80,
    align: 'center',
    render: (_, record) => <Tag color="#87d068">READY</Tag>,
  },
  {
    title: '알람코드',
    dataIndex: '',
    key: '',
    width: 100,
    align: 'center',
    render: (_, record) => 'PIT101',
  },
];
