export const events = [
  { type: 'INFO', serialNo: 'BHR-ST-350_D_1', message: '테스트 메시지', createdAt: '2022-08-01 22:10:33' },
  {
    type: 'WARN',
    serialNo: 'BHR-ST-350_D_2',
    message: '이벤트 테스트 메시지 이벤트 테스트 메시지 이벤트 테스트 메시지  테스트 메시지',
    createdAt: '2023-10-04 18:28:33',
  },
  { type: 'ALARM', serialNo: 'BHR-ST-350_D_3', message: ' 테스트 메시지', createdAt: '2023-05-23 16:28:33' },
  { type: 'ALARM', serialNo: 'BHR-ST-350_D_3', message: ' 테스트 메시지', createdAt: '2023-06-23 16:28:33' },
  { type: 'ALARM', serialNo: 'BHR-ST-350_D_3', message: ' 테스트 메시지', createdAt: '2023-07-23 16:28:33' },
  { type: 'ALARM', serialNo: 'BHR-ST-350_D_3', message: ' 테스트 메시지', createdAt: '2023-08-23 16:28:33' },
  { type: 'ALARM', serialNo: 'BHR-ST-350_D_3', message: ' 테스트 메시지', createdAt: '2023-09-23 16:28:33' },
  { type: 'ALARM', serialNo: 'BHR-ST-350_D_3', message: ' 테스트 메시지', createdAt: '2023-04-23 16:28:33' },
];
