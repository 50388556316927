import { useMutation, useQueryClient } from 'react-query';

import { customersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/customers';

const postCustomer = async (requestData) => {
  const response = await axiosInstance.post(apiUrl, requestData);
  return response;
};

export const usePostCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries(customersKey.all);
    },
  });
};
