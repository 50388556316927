import { useQuery } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';
import dayjs from 'dayjs';

const apiUrl = '/as';

const getAs = async () => {
  const response = await axiosInstance.get(apiUrl);
  return response;
};

export const useGetAs = () =>
  useQuery({
    queryFn: getAs,
    queryKey: asKey.all,
    select: (response) => {
      const compareState = (a, b) => {
        const stateOrder = {
          REQUEST: 1,
          ACCEPT: 2,
          PROCESSING: 3,
          WAIT: 4,
          DONE: 5,
          CANCEL: 6,
        };

        const statusA = stateOrder[a.status];
        const statusB = stateOrder[b.status];

        if (statusA === statusB) {
          return dayjs(b.createdAt) - dayjs(a.createdAt);
        }

        return statusA - statusB;
      };

      return {
        items: response.data.items?.sort(compareState).map((data, index) => ({
          ...data,
          index: index + 1,
          reception: `BTE_CS_RE_${dayjs(data.createdAt).format('YYYYMMDDHHmmss')}`,
        })),
        totalCount: response.data.totalCount,
      };
    },
  });
