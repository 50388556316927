import { useMutation, useQueryClient } from 'react-query';

import { usersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/users';

const postUser = async (requestData) => {
  const response = await axiosInstance.post(apiUrl, requestData);
  return response;
};

export const usePostUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postUser,
    onSuccess: () => {
      queryClient.invalidateQueries(usersKey.all);
    },
  });
};
