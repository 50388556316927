import axios from 'axios';
import { getAccessToken, clearAccessToken } from '../utils';

export const baseURL = 'https://api.h2sms.bte-inc.co.kr';

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use((request) => {
  const token = getAccessToken();
  const isNotLoginRequest = request.url !== '/login';

  if (isNotLoginRequest && !token) {
    window.location.href = '/login';
    return;
  }
  if (isNotLoginRequest && token) {
    return {
      ...request,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return request;
});

axiosInstance.interceptors.response.use(
  ({ data }) => {
    if (!data.success && data.data.message === 'invalid access token') {
      clearAccessToken();
      localStorage.clear();
      window.location.href = '/login';
      return;
    }

    return data;
  },
  (error) => {
    return {
      success: false,
      data: { error },
    };
  },
);

export { axiosInstance };
