import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { usersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/users';

const getUserDetail = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(`${apiUrl}/${requestObj.rid}`);
  return response;
};

export const useGetUserDetail = (requestObj) =>
  useQuery({
    queryFn: getUserDetail,
    queryKey: usersKey.detail(requestObj),
    select: (response) => ({
      ...response.data,
      createdAt: dayjs(response.data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: dayjs(response.data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    }),
  });
