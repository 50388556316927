import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Tag } from 'antd';

import dayjs from 'dayjs';
import { downloadExcelFile } from '../../../utils/fileDownloader';

const cx = classNames.bind(styles);

export const columns = [
  {
    title: '발생일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    fixed: 'left',
    align: 'center',
    width: 160,
    render: (_, record) => {
      return dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  {
    title: '상태',
    dataIndex: 'status',
    key: 'status',
    fixed: 'left',
    align: 'center',
    width: 100,
    render: (_, record) => {
      const { color, status } = getStatusType(record.status);
      return <Tag color={color}>{status}</Tag>;
    },
  },
  {
    title: '오류코드',
    dataIndex: 'errorCode',
    key: 'errorCode',
    align: 'center',
    fixed: 'left',
    width: 100,
    render: (_, record) => getErrorType(record.errorCode),
  },
  { title: 'Cylinder 1', width: 120, dataIndex: 'cylinder1', key: 'cylinder1', align: 'center' },
  { title: 'Cylinder 2', width: 120, dataIndex: 'cylinder2', key: 'cylinder2', align: 'center' },
  { key: 'tt101', dataIndex: 'tt101', title: 'TT101', align: 'center' },
  { key: 'tt501', dataIndex: 'tt501', title: 'TT501', align: 'center' },
  { key: 'tt102', dataIndex: 'tt102', title: 'TT102', align: 'center' },
  { key: 'tt103', dataIndex: 'tt103', title: 'TT103', align: 'center' },
  { key: 'tt401', dataIndex: 'tt401', title: 'TT401', align: 'center' },
  { key: 'tt402', dataIndex: 'tt402', title: 'TT402', align: 'center' },
  { key: 'pt101', dataIndex: 'pt101', title: 'PT101', align: 'center' },
  { key: 'pt102', dataIndex: 'pt102', title: 'PT102', align: 'center' },
  { key: 'pt201', dataIndex: 'pt201', title: 'PT201', align: 'center' },
  { key: 'pt401', dataIndex: 'pt401', title: 'PT401', align: 'center' },
  { key: 'pt402', dataIndex: 'pt402', title: 'PT402', align: 'center' },
  { key: 'pt501', dataIndex: 'pt501', title: 'PT501', align: 'center' },
  { key: 'pt401speed', dataIndex: 'pt401Speed', title: 'PT401_SPEED', width: 200, align: 'center' },
  { key: 'pt402speed', dataIndex: 'pt402Speed', title: 'PT402_SPEED ', width: 200, align: 'center' },
  { key: 'dt401', dataIndex: 'dt401', title: 'DT401', align: 'center' },
  {
    key: 'dt402',
    dataIndex: 'dt402',
    title: 'DT402',
    align: 'center',
  },
  {
    key: 'hs501',
    dataIndex: 'hs501',
    title: 'HS501',
    align: 'center',
  },
  {
    key: 'hs502',
    dataIndex: 'hs502',
    title: 'HS502',
    align: 'center',
  },
  {
    key: 'hs503',
    dataIndex: 'hs503',
    title: 'HS503',
    align: 'center',
  },
  {
    key: 'sv101',
    dataIndex: 'sv101',
    title: 'SV101',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv101 }])} />;
    },
  },
  {
    key: 'sv102',
    dataIndex: 'sv102',
    title: 'SV102',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv102 }])} />;
    },
  },
  {
    key: 'sv103',
    dataIndex: 'sv103',
    title: 'SV103 ',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv103 }])} />;
    },
  },
  {
    key: 'sv401',
    dataIndex: 'sv401',
    title: 'SV401',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv401 }])} />;
    },
  },
  {
    key: 'sv402',
    dataIndex: 'sv402',
    title: 'SV402',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv402 }])} />;
    },
  },
  {
    key: 'sv201',
    dataIndex: 'sv201',
    title: 'SV201',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv201 }])} />;
    },
  },
  {
    key: 'sv202',
    dataIndex: 'sv202',
    title: 'SV202',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.sv202 }])} />;
    },
  },
  {
    key: 'el501',
    dataIndex: 'el501',
    title: 'EL501',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.el501 }])} />;
    },
  },
  {
    key: 'el502',
    dataIndex: 'el502',
    title: 'EL502',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.el502 }])} />;
    },
  },
  {
    key: 'red',
    dataIndex: 'red',
    title: 'RED',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.red }])} />;
    },
  },
  {
    key: 'green',
    dataIndex: 'green',
    title: 'GREEN',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.green }])} />;
    },
  },
  {
    key: 'bz501',
    dataIndex: 'bz501',
    title: 'BZ501',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.bz501 }])} />;
    },
  },
  {
    key: 'ec401',
    dataIndex: 'ec401',
    title: 'EC401',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.ec401 }])} />;
    },
  },
  {
    key: 'df401',
    dataIndex: 'df401',
    title: 'DF401',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.df401 }])} />;
    },
  },
  {
    key: 'es501',
    dataIndex: 'es501',
    title: 'ES501',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.es501 }])} />;
    },
  },
  {
    key: 'fd501',
    dataIndex: 'fd501',
    title: 'FD501',
    align: 'center',
    render: (_, record) => {
      return <div className={cx(['status', { negative: !record.fd501 }])} />;
    },
  },
];

export const download = (logs) => {
  const records = logs.map((log) => {
    return {
      A: log.serialNo,
      B: log.cylinder1,
      C: log.cylinder2,
      D: dayjs(log.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      E: log.status,
      F: log.errorCode,
      G: log.tt101,
      H: log.tt501,
      I: log.tt102,
      J: log.tt103,
      K: log.tt401,
      L: log.tt402,
      M: log.pt101,
      N: log.pt102,
      O: log.pt201,
      P: log.pt401,
      Q: log.pt401Speed,
      R: log.pt402,
      S: log.pt402Speed,
      T: log.pt501,
      U: log.dt401,
      V: log.dt402,
      W: log.hs501,
      X: log.hs502,
      Y: log.hs503,
      Z: log.sv101,
      AA: log.sv102,
      AB: log.sv103,
      AC: log.sv401,
      AD: log.sv402,
      AE: log.sv201,
      AF: log.sv202,
      AG: log.el501,
      AH: log.el502,
      AI: log.red,
      AJ: log.green,
      AK: log.bz501,
      AL: log.ec401,
      AM: log.df401,
      AN: log.es501,
      AO: log.fd501,
    };
  });

  downloadExcelFile(
    [
      {
        A: '일련번호',
        B: '1번 실린더 QR',
        C: '2번 실린더 QR',
        D: '측정시점',
        E: '상태',
        F: '오류코드',
        G: 'TT101 Temperature',
        H: 'TT501 Temperature',
        I: 'TT102 Temperature',
        J: 'TT103 Temperature',
        K: 'TT401 Temperature',
        L: 'TT402 Temperature',
        M: 'PT101 Pressure',
        N: 'PT102 Pressure',
        O: 'PT201 Pressure',
        P: 'PT401 Pressure',
        Q: 'PT401 Charging speed',
        R: 'PT402 Pressure',
        S: 'PT402 Charging speed',
        T: 'PT501 Pressure',
        U: 'DT401 Distance',
        V: 'DT402 Distance',
        W: 'HS501 concentration',
        X: 'HS502 concentration',
        Y: 'HS503 concentration',
        Z: 'SV101 status',
        AA: 'SV102 status',
        AB: 'SV103 status',
        AC: 'SV401 status',
        AD: 'SV402 status',
        AE: 'SV201 status',
        AF: 'SV202 status',
        AG: 'EL501 status',
        AH: 'EL502 status',
        AI: 'RED Led status',
        AJ: 'GREEN Led status',
        AK: 'BZ501 status',
        AL: 'EC401 status',
        AM: 'DF401 status',
        AN: 'ES501 status',
        AO: 'FD501 status',
      },
    ],
    records,
    `로그내역-${dayjs().format('YYYYMMDDHHmmss')}`,
  );
};
const getStatusType = (type) => {
  switch (type) {
    case 0:
      return { color: '#00D811', status: 'READY' };
    case 1:
      return { color: '#108ee9', status: 'RUN' };
    case 2:
      return { color: 'default', status: 'STOP' };
    case 3:
      return { color: 'error', status: 'ERROR' };
    case 4:
      return { color: '#f50', status: 'EMERGENCY' };
    case 5:
      return { color: 'orange', status: 'HOLD' };
    default:
      return type;
  }
};

export const getEventType = (type) => {
  switch (type) {
    case 'INFO':
      return '정보';
    case 'WARN':
      return '경고';
    case 'ALARM':
      return '알림';
    default:
      return type;
  }
};

const getErrorType = (type) => {
  switch (type) {
    case 0:
      return 'None';
    case 1:
      return 'PT101 Low';
    case 2:
      return 'PT101 High';
    case 3:
      return 'TT101 Low';
    case 4:
      return 'TT101 High';
    case 5:
      return 'TT102 High';
    case 6:
      return 'TT103 High';
    case 7:
      return 'PT102 High';
    case 8:
      return 'PT401 High';
    case 9:
      return 'PT402 High';
    case 10:
      return 'PT401 High speed';
    case 11:
      return 'PT402 High speed';
    case 12:
      return 'TT401 High';
    case 13:
      return 'TT402 High';
    case 14:
      return 'HS501 High';
    case 15:
      return 'HS502 High';
    case 16:
      return 'HS503 High';
    case 17:
      return 'PT501 High';
    case 18:
      return 'PT102 Leak';
    case 19:
      return 'PT401 Leak';
    case 20:
      return 'PT402 Leak';
    case 21:
      return 'Depress Fail';
    case 22:
      return 'PT102 Hold';
    case 23:
      return 'FD501 Detected';
    default:
      return type;
  }
};

export const statsColumns = [
  {
    title: '알람코드',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: '알람회수',
    dataIndex: 'value',
    key: 'value',
    align: 'center',
  },
];

export const temp = [
  { code: 'PT101 Low', value: 0 },
  { code: 'PT101 High', value: 2 },
  { code: 'PT102 Low', value: 0 },
  { code: 'PT102 High', value: 0 },
  { code: 'PT102 Holding', value: 0 },
  { code: 'PT102 Leak', value: 0 },
  { code: 'PT201 Low', value: 0 },
  { code: 'PT201 High', value: 3 },
  { code: 'PT401 High', value: 0 },
  { code: 'PT402 High', value: 0 },
  { code: 'PT401 High Speed', value: 0 },
  { code: 'PT402 High Speed', value: 0 },
  { code: 'PT401 Leak', value: 0 },
  { code: 'PT402 Leak', value: 0 },
  { code: 'TT101 Low', value: 0 },
  { code: 'TT101 High', value: 2 },
  { code: 'TT401 Low', value: 0 },
  { code: 'TT402 High', value: 0 },
  { code: 'HS501 High', value: 0 },
  { code: 'HS502 High', value: 3 },
  { code: 'HS503 High', value: 0 },
  { code: 'Depress time out', value: 0 },
  { code: 'DT101 Error', value: 0 },
  { code: 'DT102 Error', value: 0 },
];
