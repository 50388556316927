import { useMutation, useQueryClient } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as';

const cancelAs = async (requestData) => {
  const response = await axiosInstance.post(`${apiUrl}/${requestData.rid}/cancel`);
  return response;
};

export const useCancelAs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelAs,
    onSuccess: () => {
      queryClient.invalidateQueries(asKey.all);
    },
  });
};
