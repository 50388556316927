import { useMutation } from 'react-query';

import { axiosInstance } from '../../base';

const apiUrl = '/login';

const postLogin = async (requestData) => {
  const response = await axiosInstance.post(apiUrl, { ...requestData });
  return response;
};

export const useLogin = () => {
  return useMutation({ mutationFn: postLogin });
};
