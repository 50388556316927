import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import classNames from 'classnames/bind';

import { useUserInfo } from '../../../hooks/userInfo';
import { getAccessToken } from '../../../utils';
import { LOGIN_PAGE } from '../../../constants/url';

import HeaderLayout from '../headerLayout';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const GlobalLayout = ({ pageTitle, children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clearUserInfo } = useUserInfo();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const title = document.querySelector('title');
      title.innerText = pageTitle;
    }
  }, [pathname]);

  useEffect(() => {
    const token = getAccessToken();

    if (typeof window !== 'undefined' && !token) {
      clearUserInfo();
      navigate('/login');
    }
  }, [pathname]);

  return (
    <div className={cx('wrapper')}>
      {pathname !== LOGIN_PAGE.link && <HeaderLayout />}
      <main className={cx('main')}>{children}</main>
    </div>
  );
};

export default GlobalLayout;
