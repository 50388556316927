import { useQuery } from 'react-query';

import { customersKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/customers';

const getCustomerDetail = async ({ queryKey: [{ requestObj }] }) => {
  const response = await axiosInstance.get(`${apiUrl}/${requestObj.rid}`, {});
  return response;
};

export const useGetCustomerDetail = (requestObj) =>
  useQuery({
    queryFn: getCustomerDetail,
    queryKey: customersKey.detail(requestObj),
    select: (response) => response.data,
  });
