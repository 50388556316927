import { Button, Drawer, Space } from 'antd';
import classNames from 'classnames/bind';

import LOGO from '../../../../assets/common/logo.png';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const TEMP = [
  {
    desc: '장비 외관 / 파손 검사',
    value: true,
  },
  {
    desc: 'GAS / Air 배관 연결 상태',
    value: false,
  },
  {
    desc: '수소 GAS 공급라인 Bubble Test',
    value: true,
  },
  {
    desc: '수소 공급 게이지 압력 [50bar]',
    value: false,
  },
  {
    desc: 'Air 공급 게이지 압력 [5bar]',
    value: true,
  },
  {
    desc: '알람 경고 문구 확인',
    value: true,
  },
  {
    desc: '각 가스 공급 밸브 동작',
    value: false,
  },
];

const AutoReport = ({ open, setIsOpen }) => {
  return (
    <Drawer
      width={'100%'}
      title={
        <div className={cx('header')}>
          <div className={cx('title')}>
            <img src={LOGO} alt="" />
            <p>자율점검 관리</p>
          </div>
        </div>
      }
      closeIcon={null}
      maskClosable={false}
      open={open}>
      <div className={cx('container')}>
        <div className={cx('wrapper')}>
          <div className={cx('space')}>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>점검일자</strong>
              <p className={cx('desc')}>2023.12.12</p>
            </div>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>점검자</strong>
              <p className={cx('desc')}>김호석</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>장비 시리얼넘버</strong>
              <p className={cx('desc')}>BHS-350-ST_D_1</p>
            </div>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>관리업체명</strong>
              <p className={cx('desc')}>DMI</p>
            </div>
          </div>
        </div>
        <div className={cx('wrapper')}>
          <strong className={cx('title')}>자율 점검표</strong>
          <table className={cx('table')}>
            <thead>
              <tr>
                <td>번호</td>
                <td>점검항목</td>
                <td>점검결과</td>
              </tr>
            </thead>
            <tbody>
              {TEMP.map((d, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.desc}</td>
                  <td>{d.value ? '정상' : '비정상'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={cx(['wrapper', 'hide'])}>
          <Space size={'large'}>
            <Button type="primary" size="large" onClick={() => setIsOpen((is) => !is)}>
              확인
            </Button>
            <Button type="primary" ghost size="large" onClick={() => window.print()}>
              인쇄
            </Button>
          </Space>
        </div>
      </div>
    </Drawer>
  );
};

export default AutoReport;
