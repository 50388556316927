export const type = [
  {
    label: '에러발생',
    value: 'ERROR',
  },
  {
    label: '동작이상',
    value: 'WARNING',
  },
  {
    label: '검사/점검 요청',
    value: 'CHECK',
  },
  {
    label: '개선요청',
    value: 'UPDATE',
  },
  {
    label: '기타',
    value: 'OTHER',
  },
];
