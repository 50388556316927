import { useMutation, useQueryClient } from 'react-query';

import { asKey } from '../../key';
import { axiosInstance } from '../../base';

const apiUrl = '/as';

const patchAs = async (requestData) => {
  const response = await axiosInstance.patch(`${apiUrl}/${requestData.rid}`, {
    isAccepted: requestData.isAccepted,
    visitDate: requestData.visitDate,
    status: requestData.status,
    memo: requestData.memo,
    waitReason: requestData.waitReason,
    visitCount: requestData.visitCount,
    doneByCall: requestData.doneByCall,
  });
  return response;
};

export const usePatchAs = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchAs,
    onSuccess: () => {
      queryClient.invalidateQueries(asKey.all);
    },
  });
};
