export const type = [
  {
    label: '에러발생',
    value: 'ERROR',
    disabled: true,
  },
  {
    label: '동작이상',
    value: 'WARNING',
    disabled: true,
  },
  {
    label: '검사/점검 요청',
    value: 'CHECK',
    disabled: true,
  },
  {
    label: '개선요청',
    value: 'UPDATE',
    disabled: true,
  },
  {
    label: '기타',
    value: 'OTHER',
    disabled: true,
  },
];

export const status = [
  {
    label: '접수중',
    value: 'REQUEST',
  },
  {
    label: '접수완료',
    value: 'ACCEPT',
  },
  {
    label: 'A/S 진행중',
    value: 'PROCESSING',
  },
  {
    label: 'A/S 대기',
    value: 'WAIT',
  },
  {
    label: 'A/S 완료',
    value: 'DONE',
    disabled: true,
  },
  {
    label: '접수취소',
    value: 'CANCEL',
    disabled: true,
  },
];

export const checkType = [
  {
    label: '하드웨어',
    value: 'HARDWARE',
    disabled: true,
  },
  {
    label: '소프트웨어',
    value: 'SOFTWARE',
    disabled: true,
  },
  {
    label: '하드/소프트',
    value: 'HARD/SOFT',
    disabled: true,
  },
  {
    label: '정기점검',
    value: 'ALWAYS',
    disabled: true,
  },
  {
    label: '기타',
    value: 'OTHER',
    disabled: true,
  },
];
