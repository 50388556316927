import { Drawer, Button, Space } from 'antd';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';

import LOGO from '../../../../assets/common/logo.png';
import { useGetAsDetail } from '../../../../apis';

import { translateType, translateCheckType } from './constatns';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const AsReport = ({ open, setIsOpen, rid }) => {
  const { data: asDetailData } = useGetAsDetail({ rid });

  if (!asDetailData) return null;

  return (
    <Drawer
      closeIcon={null}
      open={open}
      title={
        <div className={cx('header')}>
          <div className={cx('title')}>
            <img src={LOGO} alt="" />
            <p>점검 Report</p>
          </div>
          <div className={cx('process')}>
            <p className={cx('title')}>결제</p>
            <div className={cx('column')}>
              <p>작성</p>
              <div>{asDetailData.createdBy.name}</div>
            </div>
            <div className={cx('column')}>
              <p>장비담당자</p>
              <div>
                <img src={asDetailData.signature} alt="" />
              </div>
            </div>
            <div className={cx('column')}>
              <p>검토</p>
              <div />
            </div>
          </div>
        </div>
      }
      width={'100%'}
      maskClosable={false}>
      <div className={cx('container')}>
        <div className={cx('wrapper')}>
          <div className={cx('space')}>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>점검업체</strong>
              <p className={cx('desc')}>{asDetailData.customer.name}</p>
            </div>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>일련번호</strong>
              <p className={cx('desc')}>{asDetailData.serialNo}</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>신청자</strong>
              <p className={cx('desc')}>{asDetailData.createdBy.name}</p>
            </div>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>신청일자</strong>
              <p className={cx('desc')}>{dayjs(asDetailData.createdAt).format('YYYY-MM-DD')}</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>AS방문횟수</strong>
              <p className={cx('desc')}>{asDetailData.visitCount || 0}</p>
            </div>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>유선완료처리</strong>
              <p className={cx('desc')}>{asDetailData.doneByCall ? '처리' : '미처리'}</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>점검자</strong>
              <p className={cx('desc')}>{asDetailData.checkedBy?.name}</p>
            </div>
            <div className={cx(['box', 'half'])}>
              <strong className={cx('title')}>점검일자</strong>
              <p className={cx('desc')}>{dayjs(asDetailData.checkDate).format('YYYY-MM-DD')}</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box', 'triples'])}>
              <strong className={cx('title')}>접수자</strong>
              <p className={cx('desc')}>{asDetailData.acceptedBy.name}</p>
            </div>
            <div className={cx(['box', 'triples'])}>
              <strong className={cx('title')}>접수일자</strong>
              <p className={cx('desc')}>{dayjs(asDetailData.acceptedAt).format('YYYY-MM-DD')}</p>
            </div>
            <div className={cx(['box', 'triples'])}>
              <strong className={cx('title')}>완료일자</strong>
              <p className={cx('desc')}>{dayjs(asDetailData.completedAt).format('YYYY-MM-DD')}</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box'])}>
              <strong className={cx('title')}>점검구분</strong>
              <p className={cx('desc')}>
                {asDetailData.checkType.includes(',') ? (
                  asDetailData.checkType.split(',').map((type) => (
                    <span className={cx('unit')} key={type}>
                      {translateCheckType(type)}
                    </span>
                  ))
                ) : (
                  <span className={cx('unit')}>{translateCheckType(asDetailData.checkType)}</span>
                )}
              </p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box'])}>
              <strong className={cx('title')}>대기사유</strong>
              <p className={cx('desc')}>{asDetailData.waitReason || '대기없음'}</p>
            </div>
          </div>
          <div className={cx('space')}>
            <div className={cx(['box'])}>
              <strong className={cx('title')}>접수내용</strong>
              <p className={cx('desc')}>{translateType(asDetailData.type)}</p>
            </div>
          </div>
        </div>
        <div className={cx('wrapper')}>
          <table className={cx('table')}>
            <thead>
              <tr>
                <td style={{ width: '20%' }}>점검항목</td>
                <td style={{ width: '20%' }}>적합/부적합</td>
                <td style={{ width: '60%' }}>조치사항</td>
              </tr>
            </thead>
            <tbody>
              {asDetailData.afterServiceComponents.map((service) => (
                <tr key={service.rid}>
                  <td>{service.name}</td>
                  <td>{service.ok ? '적합' : '부적합'}</td>
                  <td>{service.memo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={cx('wrapper')}>
          <strong className={cx('title')}>증상</strong>
          <p className={cx('info')} readOnly>
            {asDetailData.memo}
          </p>
        </div>
        <div className={cx('wrapper')}>
          <strong className={cx('title')}>점검조치내용(서술)</strong>
          <p className={cx('info')} readOnly>
            {asDetailData.asComment}
          </p>
        </div>
        <div className={cx('wrapper')}>
          <strong className={cx('title')}>향후계획</strong>
          <p className={cx('info')} readOnly>
            {asDetailData.checkMemo}
          </p>
        </div>
        <div className={cx(['wrapper', 'hide'])}>
          <strong className={cx('title')}>첨부파일</strong>
          <Space size={'small'}>
            {asDetailData.afterServiceFiles.map((file, index) => (
              <Button key={index} target="_blank" href={file.url} download={true}>
                파일{index + 1}
              </Button>
            ))}
          </Space>
        </div>
        <div className={cx(['wrapper', 'hide'])}>
          <div className={cx('space')}>
            <Space size={'large'}>
              <Button type="primary" ghost size="large" onClick={() => setIsOpen((isPrev) => !isPrev)}>
                닫기
              </Button>
              <Button type="primary" size="large" onClick={() => window.print()}>
                출력
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default AsReport;
