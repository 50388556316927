export const customersKey = {
  all: [{ data: 'customers' }],
  detail: (requestObj) => [{ data: 'customers', requestObj }],
};

export const equipmentsKey = {
  all: [{ data: 'equipments' }],
  detail: (requestObj) => [{ data: 'equipments', requestObj }],
  components: (requestObj) => [{ data: 'equipments', requestObj }],
};

export const inspectionKey = {
  all: [{ data: 'inspection' }],
  list: (requestObj) => [{ data: 'inspection', requestObj }],
};

export const userEquipments = {
  all: [{ data: 'userEquipment' }],
};

export const logsKey = {
  all: [{ data: 'logs' }],
  list: (requestObj) => [{ data: 'logs', requestObj }],
};

export const usersKey = {
  all: [{ data: 'users' }],
  detail: (requestObj) => [{ data: 'users', requestObj }],
};

export const componentsKey = {
  all: [{ data: 'components' }],
};

export const asKey = {
  all: [{ data: 'as' }],
  detail: (requestObj) => [{ data: 'as', requestObj }],
  stats: (requestObj) => [{ data: 'as', requestObj }],
};
