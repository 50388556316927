import { Row, Input, Space, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames/bind';

import { ContentsLayout } from '../../../components/layout';
import { useGetEquipments } from '../../../apis';

import { columns } from './constants';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Equipments = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const { data: equipmentsData } = useGetEquipments();

  return (
    <>
      <ContentsLayout headTitle="장비내역">
        <div className={cx('container')}>
          <Row align={'middle'} justify={'space-between'}>
            <Space align="center">
              <strong className={cx('search')}>검색</strong>
              <Input
                placeholder="업체명, 일련번호"
                onChange={(onChangeEvent) => {
                  const currentValue = onChangeEvent.target.value;
                  if (currentValue.includes('BTE')) {
                    setSearch('');
                  } else {
                    setSearch(onChangeEvent.target.value);
                  }
                }}
              />
            </Space>
          </Row>

          <Table
            size="small"
            dataSource={
              equipmentsData?.filter((data) => {
                if (search === '') return data;
                return data.customer.name.includes(search) || data.serialNo.includes(search);
              }) || []
            }
            scroll={{
              x: 1280,
              y: 600,
            }}
            virtual
            rowKey="rid"
            onRow={(record) => {
              return {
                onClick: () => navigate(`/equipments/detail/${record.rid}`),
              };
            }}
            pagination={false}
            columns={columns}
          />
        </div>
      </ContentsLayout>
    </>
  );
};

export default Equipments;
